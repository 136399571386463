import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import FileInput, {
    ecoItemCoverType,
    getBase64,
    imageTypes
} from "../../../components/common/inputs/file-input/FileInput";
import PageTitle from "../../../components/common/page-title/PageTitle";
import RedButton from "../../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../../components/common/loading/LoadingOval";
import InsideContentForm from "../../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../../components/common/inputs/text-input/TextInput";
import Notice from "../../../components/common/notice/Notice";
import DividerFooter from "../../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../../components/common/footer/InsideContentFormFooter";
import Button from "../../../components/common/buttons/Button";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import TextArea from "../../../components/common/inputs/text-area/TextArea";
import SelectInput from "../../../components/common/inputs/select-input/SelectInput";
import FileUploader from "../../../helpers/FileUploader";
import Book from "../../../classes/Book";
import BooksCategory from "../../../classes/BooksCategory";

const EditBook = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [book, setBook] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [coverImageLocalFile, setCoverImageLocalFile] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [booksCategories, setBooksCategories] = useState([]);
    const [booksCategoriesKeyValue, setBooksCategoriesKeyValue] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        saveBook(book, title, description, url, selectedCategory, coverImageLocalFile, (coverImageLocalFile != null));
    };

    const saveBook = async (book,
                            title,
                            description,
                            url,
                            selectedCategory,
                            file,
                            fileWasChanged) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            let pBook = book

            if (book == null) {
                pBook = new Book();
            }

            pBook.setTitle(title);
            pBook.setDescriptionString(description)
            pBook.setUrl(url);
            pBook.setCategory(selectedCategory);

            if (fileWasChanged) {
                const coverImageFile = await FileUploader.uploadFile(file, "book_cover_photo",
                    file["type"]);
                pBook.setCoverPhotoFile(coverImageFile);
            }

            pBook.setEnabled(true);
            await pBook.save();

            setSuccess(true);
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (book === null) {
                cleanValues();
            }
        }
    }

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchBook = async () => {
            try {
                setLoading(true);
                const book = await Book.getQuery().get(params.id)

                if (isMounted) {
                    setBook(book);
                    setTitle(book.getTitle());
                    setDescription(book.getDescriptionString());
                    setUrl(book.getUrl());
                    setSelectedCategory(book.getCategory());
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchBook();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchBooksCategory = async () => {
            try {
                const booksCategories = await BooksCategory.getPickableQuery().find();
                if (isMounted) {
                    const mappedResults = booksCategories.map(function(item) { return {
                        key: item.id,
                        value: item.getTitle()
                    }});
                    setBooksCategories(booksCategories);
                    setBooksCategoriesKeyValue(mappedResults);
                    if (params.id === undefined) {
                        setSelectedCategory(booksCategories[0]);
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchBooksCategory();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const confirmDelete = (book) => {
        if (book != null) {
            confirmAlert({
                title: 'Delete book',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteBook(book)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const deleteBook = async (book) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            book.setEnabled(false);

            await book.save();

            navigate("/books");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (book === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setBook(null);
        setTitle('');
        setDescription('');
        setUrl('');
        setCoverImageLocalFile(null);
    }

    const booksCategorySelectChangeHandler = (e) => {
        const booksCategory = booksCategories.filter(option => option.id === e.key)[0]
        setSelectedCategory(booksCategory);
    }

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];

        getBase64(file)
            .then(result => {
                file["base64"] = result;
                setCoverImageLocalFile(file);
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <div className="Book">
            <PageTitle title="Book"
                       brief="Add a book" >
                { book != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(book)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="book-cover-image"
                                                             label="Cover image">
                                            <FileInput name="book-cover-image"
                                                       title="Select a cover image"
                                                       accept={imageTypes}
                                                       previewImage={
                                                           coverImageLocalFile !== null ?
                                                               coverImageLocalFile["base64"] :
                                                               book !== null ?
                                                                   book.getCoverPhotoUrl() :
                                                                   null }
                                                       type={ecoItemCoverType}
                                                       onChange={handleFileInputChange}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="book-title"
                                                             label="Title">
                                            <TextInput id="book-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="book-description"
                                                             label="Description">
                                            <TextArea id="book-description"
                                                      value={description}
                                                      rows={8}
                                                      required={true}
                                                      autoComplete={false}
                                                      onChange={(e) => setDescription(e.target.value)} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="book-url"
                                                             label="URL">
                                            <TextInput id="book-url"
                                                       value={url}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setUrl(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                {booksCategoriesKeyValue && booksCategoriesKeyValue.length > 0 && selectedCategory != null &&
                                    <Row gutterWidth={20}>
                                        <Col>
                                            <LeftAlignLabelField id="book-category"
                                                                 label={"Category"}>
                                                <SelectInput name="book-category"
                                                             options={booksCategoriesKeyValue}
                                                             preSelectedOption={{
                                                                 key: selectedCategory.id,
                                                                 value: selectedCategory.getTitle()
                                                             }}
                                                             onChange={booksCategorySelectChangeHandler}/>
                                            </LeftAlignLabelField>
                                        </Col>
                                    </Row>
                                }
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The book was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditBook;