import Parse from "parse";

class VideoCategory extends Parse.Object {
    constructor() {
        super('VideoCategory');
    }

    getTitle() {
        return this.get('title');
    }

    setTitle(title) {
        this.set('title', title)
    }

    getSpanishTitle() {
        return this.get('spanishTitle');
    }

    setSpanishTitle(spanishTitle) {
        this.set('spanishTitle', spanishTitle);
    }

    getImageUrl() {
        return this.get("image").url()
    }

    setImageFile(file) {
        this.set("image", file);
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    static getQuery() {
        const parseQuery = new Parse.Query(VideoCategory);
        parseQuery.equalTo("enabled", true);
        parseQuery.ascending("order");
        return parseQuery;
    }

    static getPickableQuery() {
        const parseQuery = new Parse.Query(VideoCategory);
        parseQuery.equalTo("enabled", true);
        parseQuery.ascending("title");
        return parseQuery;
    }
}

export default VideoCategory;