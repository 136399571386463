import React, {useEffect, useRef, useState} from 'react';

import Card from "../../components/common/cards/Card";
import SectionTitle from "../../components/common/section-title/SectionTitle";
import "./log-in.scss"

import TopAlignLabelField from "../../components/common/inputs/top-align-label-field/TopAlignLabelField";
import Form from "../../components/common/form/Form";
import {Col, Container, Row} from "react-grid-system";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import Hyperlink from "../../components/common/hyperlink/Hyperlink";
import TextInput from "../../components/common/inputs/text-input/TextInput";

import LoadingOval from "../../components/common/loading/LoadingOval";
import LoadingContainer from "../../components/common/loading/LoadingContainer";
import FormErrorFooter from "../../components/common/footer/FormErrorFooter";
import useAuth from "../../hooks/useAuth";
import {useLocation, useNavigate} from "react-router-dom";
import Parse from "parse";
import CustomUser from "../../classes/CustomUser";

// import useCookie from "../../hooks/useCookie";

const EMAIL_REGEX =  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
// TODO: uncomment this
// const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const LOG_IN_URL = "Login"
export const COOKIE_KEY = "ATPB"
export const EMAIL_COOKIE_KEY = "ATPBEmail"

const LogIn = () => {

    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/products";

    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    // const [cookie, updateCookie] = useCookie(COOKIE_KEY, "")
    // const [emailCookie, updateEmailCookie] = useCookie(EMAIL_COOKIE_KEY, "");

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {

    }, [email, password ])

    useEffect(() => {
        const result = EMAIL_REGEX.test(email);
        setValidEmail(result);
    }, [email])

    // useEffect(() => {
    //     const result = PASSWORD_REGEX.test(password);
    //     console.log(result);
    //     console.log(password);
    //     setValidPassword(result);
    // }, [password])

    useEffect(() => {
        setErrorMessage('');
    }, [email, password])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const v1 = EMAIL_REGEX.test(email);
        // const v2 = PASSWORD_REGEX.test(password);
        if (!v1) {
            setErrorMessage("Invalid data. Try again later.")
            return;
        }
        logIn(email, password);
    }

    function cleanValues() {
        setEmail("");
        setPassword("");
    }

    const logIn = async (email, password) => {
        try {
            setLoading(true);
            setErrorMessage("");
            const user = await CustomUser.logIn(email, password);
            cleanValues();
            setSuccess(true);
            navigate(from, { replace: true });
        }
        catch (error) {
            setSuccess(false);
            console.log(error.code);
            console.log(error.message);
            if (error.code === Parse.Error.OBJECT_NOT_FOUND) {
                setErrorMessage(error.message);
            }
            else {
                setErrorMessage(error.message);
                // setErrorMessage("Se perdió la conexión. Por favor intente nuevamente.")
            }
            errRef.current.focus();
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <div className="LogIn">
            <div className="LogIn__container">
                <div className="LogIn__logo-container">
                </div>
                <Card>
                    <div className="Card__flex-container">
                        <SectionTitle title="Log in to your account"/>
                        <form onSubmit={handleSubmit}>
                            <Form>
                                <div className="Form__section-fields">
                                    <Container fluid>
                                        <Row gutterWidth={20}>
                                            <Col>
                                                <TopAlignLabelField id="email" label="Email">
                                                    <TextInput
                                                        id="email"
                                                        placeholder="Email"
                                                        innerRef={emailRef}
                                                        value={email}
                                                        required={true}
                                                        autoComplete={false}
                                                        onFocus={() => setEmailFocus(true)}
                                                        onBlur={() => setEmailFocus(false)}
                                                        onChange={(e) => setEmail(e.target.value)} />
                                                </TopAlignLabelField>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row gutterWidth={20}>
                                            <Col>
                                                <TopAlignLabelField id="password" label="Password">
                                                    <TextInput id="password"
                                                               type="password"
                                                               value={password}
                                                               placeholder="Password"
                                                               required={true}
                                                               onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </TopAlignLabelField>
                                            </Col>
                                        </Row>
                                        <br/>
                                    </Container>
                                    <FormErrorFooter innerRef={errRef} errorMessage={errorMessage}/>
                                    <br/>
                                    <div className="Form__button_row">
                                        {loading ?
                                            <LoadingContainer>
                                                <LoadingOval loading={loading}/>
                                            </LoadingContainer>
                                            :
                                            <PrimaryButton title="Log in" type="submit" fill={true} />
                                        }
                                    </div>
                                </div>
                            </Form>
                        </form>
                    </div>
                </Card>
                <div className="LogIn__footer">
                    <Hyperlink href="https://www.casa-earth.com" text={"© Casa Earth"} />
                    <span> • </span>
                    <Hyperlink href="https://www.casa-earth.com" text={"Contact"} />
                    <span> • </span>
                    <Hyperlink href="https://www.casa-earth.com" text={"Privacy Policy"} />
                </div>
            </div>
        </div>
    );

};

export default LogIn;