import React from 'react';

import "./top-align-label-field.scss"

const TopAlignLabelField = ({children, id, label}) => {
    return (
        <div className="TopAlignLabelField">
            <label htmlFor={id} className={"TopAlignLabelField__label"}>
                {label}
            </label>
            {children}
        </div>
    );
}

export default TopAlignLabelField;