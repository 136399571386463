import React, {useEffect} from 'react';

import "./business-header.scss"
import CustomUser from "../../../classes/CustomUser";

const BusinessHeader = () => {

    const currentUser = CustomUser.current();

    useEffect(() => {
    }, []);

    return (
        <div className="business-header divider-bottom-1">
            <div className="business-header__logo-container">
                <img src={ currentUser.get("profilePicture")?.url() } alt="" className="business-header__logo" />
            </div>
            <span className="business-header__business-name">{currentUser.get("fullName")}</span>
        </div>
    );
};

export default BusinessHeader;
