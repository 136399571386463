import Parse from "parse";

class Category extends Parse.Object {

    constructor() {
        super('Category');
    }

    getTitle() {
        return this.get('name');
    }

    setTitle(title) {
        this.set('name', title)
    }

    getSpanishTitle() {
        return this.get('spanishTitle');
    }

    setSpanishTitle(spanishTitle) {
        this.set('spanishTitle', spanishTitle);
    }

    setPickable(pickable) {
        this.set('pickable', pickable);
    }

    getOrder() {
        return this.get('order');
    }

    setOrder(order) {
        this.set('order', order);
    }

    isPickable() {
        return this.get('pickable');
    }

    setEditable(editable) {
        this.set('editable', editable);
    }

    isEditable(editable) {
        return this.get('editable');
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    getImageUrl() {
        return this.get("image").url();
    }

    setImageFile(file) {
        this.set("image", file);
    }

    static getPickableQuery() {
        const parseQuery = new Parse.Query(Category);
        parseQuery.equalTo("enabled", true);
        parseQuery.equalTo("pickable", true);
        parseQuery.ascending("order");
        return parseQuery;
    }

    static getAllQuery() {
        const parseQuery = new Parse.Query(Category);
        parseQuery.equalTo("enabled", true);
        parseQuery.ascending("order");
        return parseQuery;
    }
}

export default Category;