import React from 'react';

import "./table.scss"
import LoadingContainer from "../common/loading/LoadingContainer";
import LoadingOval from "../common/loading/LoadingOval";
import {useNavigate} from "react-router-dom";

const EcoDictionaryTable = ({items, loading}) => {

    const navigate = useNavigate();

    const handleTableRowClick = (entry) => {
        navigate("./" + entry.id + "/edit", { state: { entry: entry } })
    }

    return (
        <div>
            <table className="Table">
                <thead>
                <tr>
                    <td className="Table__header-data">
                        <div className="Table__data-container">
                            <span>
                                Term
                            </span>
                        </div>
                    </td>
                    <td className="Table__header-data">
                        <div className="Table__data-container">
                            <span>
                                Meaning
                            </span>
                        </div>
                    </td>
                </tr>
                </thead>
                { loading === false &&
                    <tbody>
                    { items && items.map(item =>
                        <tr className="Table__row" key={item.id} onClick={ () => handleTableRowClick(item) } >
                            <td className="Table__body-data">
                                <div className="Table__data-container">
                            <span className="Table__primary-text-span">
                                { item.getTerm() }
                            </span>
                                </div>
                            </td>
                            <td className="Table__body-data">
                                <div className="Table__data-container">
                            <span className="Table__secondary-text-span Table__two-lines-span">
                                { item.getMeaning() }
                            </span>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                }
            </table>
            { loading === true &&
                <LoadingContainer>
                    <LoadingOval loading={loading}/>
                </LoadingContainer>
            }
        </div>
    );
};


export default EcoDictionaryTable;