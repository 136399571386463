import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Languages} from "../../utilities/Constants";
import FileUploader from "../../helpers/FileUploader";
import Video from "../../classes/Video";
import {confirmAlert} from "react-confirm-alert";
import PageTitle from "../../components/common/page-title/PageTitle";
import RedButton from "../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../components/common/loading/LoadingOval";
import InsideContentForm from "../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../components/common/inputs/text-input/TextInput";
import TextArea from "../../components/common/inputs/text-area/TextArea";
import SelectInput from "../../components/common/inputs/select-input/SelectInput";
import Notice from "../../components/common/notice/Notice";
import DividerFooter from "../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../components/common/footer/InsideContentFormFooter";
import Button from "../../components/common/buttons/Button";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import FileInput, {
    getBase64,
    imageTypes,
    videoCoverType
} from "../../components/common/inputs/file-input/FileInput";
import VideoCategory from "../../classes/VideoCategory";

const EditVideo = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [video, setVideo] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [coverImageLocalFile, setCoverImageLocalFile] = useState(null);
    const [title, setTitle] = useState('');
    const [tagline, setTagline] = useState('');
    const [body, setBody] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState(Languages[0]);
    const [videosCategories, setVideosCategories] = useState([]);
    const [videosCategoriesKeyValue, setVideosCategoriesKeyValue] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        saveVideo(video, title, tagline, body, videoUrl, selectedLanguage.key, selectedCategory, coverImageLocalFile, (coverImageLocalFile != null));
    };

    const saveVideo = async (video,
                             title,
                             tagline,
                             body,
                             videoUrl,
                             language,
                             selectedCategory,
                             file,
                             fileWasChanged) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            let pVideo = video

            if (video == null) {
                pVideo = new Video();
            }

            pVideo.setTitle(title);
            pVideo.setTagline(tagline);
            pVideo.setBody(body);
            pVideo.setVideoURL(videoUrl);
            pVideo.setCategory(selectedCategory);
            pVideo.setLanguageCode(language)

            if (fileWasChanged) {
                const coverImageFile = await FileUploader.uploadFile(file, "video_cover_photo",
                    file["type"]);
                pVideo.setCoverPhotoFile(coverImageFile);
            }

            pVideo.setEnabled(true);
            await pVideo.save();

            setSuccess(true);
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (video === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setVideo(null);
        setTitle('');
        setTagline('');
        setBody('');
        setVideoUrl('');
        setCoverImageLocalFile(null);
        setSelectedLanguage(Languages[0]);
    }

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchVideo = async () => {
            try {
                setLoading(true);
                const video = await Video.getQuery().get(params.id)

                if (isMounted) {
                    setVideo(video);
                    setTitle(video.getTitle());
                    setTagline(video.getTagline());
                    setBody(video.getBody());
                    setVideoUrl(video.getVideoURL());
                    setSelectedLanguage(video.getLanguage());
                    setSelectedCategory(video.getCategory());
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchVideo();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchVideosCategory = async () => {
            try {
                const videosCategories = await VideoCategory.getPickableQuery().find();
                if (isMounted) {
                    const mappedResults = videosCategories.map(function(item) { return {
                        key: item.id,
                        value: item.getTitle()
                    }});
                    setVideosCategories(videosCategories);
                    setVideosCategoriesKeyValue(mappedResults);
                    if (params.id === undefined) {
                        setSelectedCategory(videosCategories[0]);
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchVideosCategory();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const confirmDelete = (video) => {
        if (video != null) {
            confirmAlert({
                title: 'Delete video',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteVideo(video)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const deleteVideo = async (video) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            video.setEnabled(false);

            await video.save();

            navigate("/videos");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (video === null) {
                cleanValues();
            }
        }
    }

    const languageSelectChangeHandler = (e) => {
        setSelectedLanguage(e);
    }

    const videosCategorySelectChangeHandler = (e) => {
        const videosCategory = videosCategories.filter(option => option.id === e.key)[0]
        setSelectedCategory(videosCategory);
    }

    const handleFileInputChange = async (e) => {
        const file = e.target.files[0];
        try {
            file["base64"] = await getBase64(file);
            setCoverImageLocalFile(file);
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="EditVideo">
            <PageTitle title="Video"
                       brief="Add a video" >
                { video != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(video)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="video-cover-image"
                                                             label="Cover image">
                                            <FileInput name="video-cover-image"
                                                       title="Select a cover image"
                                                       accept={imageTypes}
                                                       previewImage={
                                                           coverImageLocalFile !== null ?
                                                               coverImageLocalFile["base64"] :
                                                               video !== null ?
                                                                   video.getImageUrl() :
                                                                   null }
                                                       type={videoCoverType}
                                                       onChange={handleFileInputChange}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="video-title"
                                                             label="Title">
                                            <TextInput id="video-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="video-tagline"
                                                             label="Episode number / Tagline">
                                            <TextInput id="video-tagline"
                                                       value={tagline}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTagline(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="video-body"
                                                             label="Description">
                                            <TextArea id="video-body"
                                                      value={body}
                                                      rows={8}
                                                      required={true}
                                                      autoComplete={false}
                                                      onChange={(e) => setBody(e.target.value)} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="video-url"
                                                             label="YouTube Video URL">
                                            <TextInput id="video-url"
                                                       value={videoUrl}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setVideoUrl(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                {videosCategoriesKeyValue && videosCategoriesKeyValue.length > 0 && selectedCategory != null &&
                                    <Row gutterWidth={20}>
                                        <Col>
                                            <LeftAlignLabelField id="video-category"
                                                                 label={"Category"}>
                                                <SelectInput name="video-category"
                                                             options={videosCategoriesKeyValue}
                                                             preSelectedOption={{
                                                                 key: selectedCategory.id,
                                                                 value: selectedCategory.getTitle()
                                                             }}
                                                             onChange={videosCategorySelectChangeHandler}/>
                                            </LeftAlignLabelField>
                                        </Col>
                                    </Row>
                                }
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="video-language-code"
                                                             label={"Language"}>
                                            <SelectInput name="video-language-code"
                                                         options={Languages}
                                                         preSelectedOption={selectedLanguage}
                                                         onChange={languageSelectChangeHandler}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The video was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditVideo;