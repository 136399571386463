import React from 'react';

import "./audio-input.scss"
import ReactAudioPlayer from "react-audio-player";

const AudioInput = ({title, name, accept, src, onChange, onAudioMetadataChange}) => {
    return (
        <div className="AudioFileInput">
            <ReactAudioPlayer
                src={src}
                controls
                loop={false}
                preload={"auto"}
                onLoadedMetadata={onAudioMetadataChange}
            />
            <span className="AudioFileInput__filename_span">{title}</span>
            <div className="AudioFileInput__upload_container">
                <input className="AudioFileInput__input"
                       title={title}
                       type="file"
                       accept={accept}
                       name={name}
                       onChange={onChange} />
                <span className="FileInput__button-span">Upload</span>
            </div>
        </div>
    );
};

export default AudioInput;