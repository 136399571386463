import React from 'react';

const InsideContentFormPaddingContainer = ({children}) => {
    return (
        <div className="Form__inside-content-padding-container">
            {children}
        </div>
    );
};

export default InsideContentFormPaddingContainer;