import React from 'react';

import "./button.scss"

const TextButton = ({text, type = "", onClick}) => {
    return (
        <button type="button" className={"TextButton " + type + "TextButton"} onClick={onClick}>
            <span className={"TextButton__text " + type + "TextButton__text" }>{text}</span>
        </button>
    );
};

export default TextButton;