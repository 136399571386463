import Parse from "parse";

class FileUploader {

    static async uploadFile(file, saveAs, type) {
        try {
            const name = saveAs + this.getExtensionForType(type) + "";
            const parseFile = new Parse.File(name, file, type);
            return parseFile.save();
        }
        catch (error) {
            throw error;
        }
    }

    static getExtensionForType(type) {
        if (type === "image/jpeg") {
            return ".jpeg";
        }
        else if (type === "image/jpg") {
            return ".jpg";
        }
        else if (type === "image/png") {
            return ".png";
        }
        else if (type === "audio/mp3") {
            return ".mp3";
        }
        else if (type === "audio/mpeg") {
            return ".mpeg";
        }
        return "";
    }
}

export default FileUploader;