import React, {Component} from 'react';

import "./divider-footer.scss"

class DividerFooter extends Component {
    render() {
        return (
            <div className="DividerFooter divider-bottom-1" />
        );
    }
}

export default DividerFooter;