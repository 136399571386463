import React, {useEffect, useState} from "react";

import { FiChevronDown } from "react-icons/fi";

import "./select-input.scss"

const SelectInput = ({name, options, onChange, preSelectedOption}) => {

    const [selected, setSelected] = useState(
        preSelectedOption && preSelectedOption.key !== undefined
            ? preSelectedOption.key
            : options[0].key);

    const selectChangeHandler = (e) => {
        setSelected(e.target.value);
        const selected = options.filter(option => option.key === e.target.value)[0]
        onChange(selected)
    }

    useEffect(() => {
        setSelected(preSelectedOption.key)
    }, [preSelectedOption])

    return (
        <div className={"Select__input-container"}>
            <select id={name} value={selected} className="Select__input" onChange={selectChangeHandler}>
                { options.map(option =>
                    <option key={option.key} value={option.key}>{option.value}</option>
                )}
            </select>
            <FiChevronDown className="Select__chevron" />
        </div>
    )
}

export default SelectInput