class LocalPostSection {

    constructor() {
        this.subtitle = null;
        this.body = "";
        this.imageUrl = null;
        this.localImage = null;
        this.postSection = null;
        this.deleted = false;
    }

    getPostSection() {
        return this.postSection;
    }

    setPostSection(postSection) {
        this.postSection = postSection;
    }

    getSubtitle() {
        return this.subtitle;
    }

    setSubtitle(subtitle) {
        this.subtitle = subtitle;
    }

    getBody() {
        return this.body;
    }

    setBody(body) {
        this.body = body;
    }

    getImageUrl() {
        return this.imageUrl;
    }

    setImageUrl(imageUrl) {
        this.imageUrl = imageUrl;
    }

    getLocalImage() {
        return this.localImage;
    }

    setLocalImage(image) {
        this.localImage = image;
    }

    isDeleted() {
        return this.deleted;
    }

    setDeleted(deleted) {
        this.deleted = deleted;
    }
}

export default LocalPostSection;