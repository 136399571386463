import React from 'react';
import Form from "./Form";

const InsideContentForm = (props) => {
    return (
        <div className="Form__inside-content-container">
            <Form>
                {props.children}
            </Form>
        </div>
    );
};

export default InsideContentForm;