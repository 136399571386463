import React from 'react';

import "./dashed-line-separator.scss"

const DashedLineSeparator = () => {
    return (
        <hr className="DashedLineSeparator" />
    );
};

export default DashedLineSeparator;