import React from 'react';

import "./page-title.scss"

const PageTitle = ({title, brief, children}) => {
    return (
        <div className="PageTitle divider-bottom-1">
            <div className="PageTitle__top-bar-container">
                <span className="PageTitle__title">{title}</span>
                <div className={'PageTitle__buttons_bar'}>
                    {children}
                </div>
            </div>
            { brief !== undefined &&
                <div className="PageTitle__brief-container">
                    <span className="PageTitle__brief">{brief}</span>
                </div>
            }
        </div>
    );
};

export default PageTitle;