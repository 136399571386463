import React from 'react';
import FileInput, {imageTypes, productImageType} from "../file-input/FileInput";

import "./multiple-images-input.scss"

const MultipleImagesInput = ({title, name, previewImages, onChange, onDelete}) => {

    function previewImageForIndex(index) {
        if (previewImages != null && previewImages.length > index) {
            return previewImages[index].source;
        }
        return null;
    }

    function onDeleteHandlerForIndex(index) {
        return previewImageForIndex(index) != null ? () => onDelete(index) : null
    }

    return (
        <div className="MultiplesImagesInput">
            <FileInput title={title}
                       name={name + "_1"}
                       previewImage={previewImageForIndex(0)}
                       type={productImageType}
                       accept={imageTypes}
                       multiple={true}
                       onChange={onChange}
                       onDelete={ onDeleteHandlerForIndex(0) } />
            <FileInput title={title}
                       name={name + "_2"}
                       previewImage={previewImageForIndex(1)}
                       type={productImageType}
                       accept={imageTypes}
                       multiple={true}
                       onChange={onChange}
                       onDelete={ onDeleteHandlerForIndex(1) } />
            <FileInput title={title}
                       name={name + "_3"}
                       previewImage={previewImageForIndex(2)}
                       type={productImageType}
                       accept={imageTypes}
                       multiple={true}
                       onChange={onChange}
                       onDelete={onDeleteHandlerForIndex(2)} />
            <FileInput title={title}
                       name={name + "_4"}
                       previewImage={previewImageForIndex(3)}
                       type={productImageType}
                       accept={imageTypes}
                       multiple={true}
                       onChange={onChange}
                       onDelete={onDeleteHandlerForIndex(3)} />
        </div>
    );
};

export default MultipleImagesInput;