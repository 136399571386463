import React from 'react';

import "./table-footer.scss"
import Button from "../buttons/Button";
import ButtonsHorizontalBar from "../bars/ButtonsHorizontalBar";

const PaginationTableFooter = ({loading, currentPage, numberOfPages, onPrevious, onNext}) => {
    return (
        <div className={'TableFooter'} >
            <span className="TableFooter__text" >
                { loading ? "Loading..." : (currentPage + " of " + numberOfPages + (numberOfPages === 1 ? " page" : " pages")) }
            </span>
            <ButtonsHorizontalBar>
                <Button title="Previous" disabled={loading || currentPage === 1 } onClick={ onPrevious } />
                <Button title="Next" disabled={loading || currentPage === numberOfPages} onClick={ onNext } />
            </ButtonsHorizontalBar>
        </div>
    );
};

export default PaginationTableFooter;