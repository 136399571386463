import React, {useEffect, useState} from 'react';
import PageTitle from "../../../components/common/page-title/PageTitle";
import EcoDictionaryTable from "../../../components/tables/EcoDictionaryTable";
import {Link} from "react-router-dom";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import {FiPlus} from "react-icons/fi";
import DictionaryEntry from "../../../classes/DictionaryEntry";

const EcoDictionaryEntries = () => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchEntries = async () => {
            try {
                const results = await DictionaryEntry.getQuery().find();
                if (isMounted) {
                    setItems(results);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchEntries();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    return (
        <div>
            <PageTitle title="Eco-dictionary">
                <Link to="./new">
                    <PrimaryButton title="New term" icon={<FiPlus/>}/>
                </Link>
            </PageTitle>
            <EcoDictionaryTable items={items} loading={loading} />
        </div>
    );
};

export default EcoDictionaryEntries;