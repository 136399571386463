import React, {useEffect, useState} from 'react';
import PageTitle from "../../../../components/common/page-title/PageTitle";
import {Link} from "react-router-dom";
import PrimaryButton from "../../../../components/common/buttons/PrimaryButton";
import {FiPlus} from "react-icons/fi";
import EcoCategoriesTable from "../../../../components/tables/EcoCategoriesTable";
import BooksCategory from "../../../../classes/BooksCategory";

const BooksCategories = () => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchBooksCategories = async () => {
            try {
                const results = await BooksCategory.getPickableQuery().find();
                if (isMounted) {
                    setItems(results);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchBooksCategories();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    return (
        <div>
            <PageTitle title="Books categories">
                <Link to="./new">
                    <PrimaryButton title="New category" icon={<FiPlus/>}/>
                </Link>
            </PageTitle>
            <EcoCategoriesTable items={items} loading={loading} />
        </div>
    );
};

export default BooksCategories;