export const Languages = [
    {
        key: 'en',
        value: 'English'
    },
    {
        key: 'es',
        value: 'Español'
    }
]

export const ShopCategoryTitleMinLength = 2;
export const ShopCategoryTitleMaxLength = 200;
export const ShopCategoryCoverImageMaxSize = 750000; //(750 KB)