import React from 'react';

import "./section-title.scss"

const SectionTitle = ({title, brief}) => {
    return (
        <div className="SectionTitle divider-bottom-1">
            <span className="SectionTitle__title">{title}</span>
            { brief !== undefined &&
                <div className="SectionTitle__brief-container">
                    <span className="SectionTitle__brief">{brief}</span>
                </div>
            }
        </div>
    );
};

export default SectionTitle;