import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-grid-system";
import PageTitle from "../../../components/common/page-title/PageTitle";
import LoadingOval from "../../../components/common/loading/LoadingOval";
import InsideContentForm from "../../../components/common/form/InsideContentForm";
import LeftAlignLabelField from "../../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import DividerFooter from "../../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../../components/common/footer/InsideContentFormFooter";
import Button from "../../../components/common/buttons/Button";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import TextInput from "../../../components/common/inputs/text-input/TextInput";
import {useNavigate, useParams} from "react-router-dom";
import TextArea from "../../../components/common/inputs/text-area/TextArea";
import DictionaryEntry from "../../../classes/DictionaryEntry";
import Notice from "../../../components/common/notice/Notice";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import SelectInput from "../../../components/common/inputs/select-input/SelectInput";
import {Languages} from "../../../utilities/Constants";
import RedButton from "../../../components/common/buttons/RedButton";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const EditEcoDictionary = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [entry, setEntry] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [term, setTerm] = useState('');
    const [meaning, setMeaning] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState(Languages[0]);

    const languageSelectChangeHandler = (e) => {
        setSelectedLanguage(e);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        saveDictionaryEntry(entry, term, meaning, selectedLanguage.key);
    };

    const saveDictionaryEntry = async (entry, term, meaning, language) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            let pEntry = entry

            if (entry == null) {
                pEntry = new DictionaryEntry();
            }

            pEntry.setTerm(term);
            pEntry.setMeaning(meaning);
            pEntry.setLanguageCode(language)
            pEntry.setEnabled(true);

            await pEntry.save();

            setSuccess(true);
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (entry === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setEntry(null);
        setTerm('');
        setMeaning('');
        setSelectedLanguage(Languages[0]);
    }

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchEntry = async () => {
            try {
                setLoading(true);
                const entry = await DictionaryEntry.getQuery().get(params.id)

                if (isMounted) {
                    setEntry(entry);
                    setTerm(entry.getTerm());
                    setMeaning(entry.getMeaning());
                    setSelectedLanguage(entry.getLanguage());
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchEntry();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const confirmDelete = (entry) => {
        if (entry != null) {
            confirmAlert({
                title: 'Delete dictionary entry',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteDictionaryEntry(entry)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const deleteDictionaryEntry = async (entry) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            entry.setEnabled(false);

            await entry.save();

            navigate("/eco-dictionary");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (entry === null) {
                cleanValues();
            }
        }
    }

    return (
        <div className="EditEcoDictionary">
            <PageTitle title="Dictionary entry"
                       brief="Add a dictionary entry" >
                { entry != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(entry)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="eco-dictionary-term"
                                                             label="Term">
                                            <TextInput id="eco-dictionary-term"
                                                       value={term}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTerm(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="eco-dictionary-meaning"
                                                             label="Meaning">
                                            <TextArea id="eco-dictionary-meaning"
                                                      value={meaning}
                                                      rows={8}
                                                      required={true}
                                                      autoComplete={false}
                                                      onChange={(e) => setMeaning(e.target.value)} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="eco-dictionary-language-code"
                                                             label={"Language"}>
                                            <SelectInput name="eco-dictionary-language-code"
                                                         options={Languages}
                                                         preSelectedOption={selectedLanguage}
                                                         onChange={languageSelectChangeHandler}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The entry was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditEcoDictionary;