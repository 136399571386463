import React from 'react';

import "./fields-title.scss"

const FieldsTitle = ({title, children}) => {
    return (
        <div className="FieldsTitle">
            <span className="FieldsTitle__span">{title}</span>
            {children}
        </div>
    );
};

export default FieldsTitle;