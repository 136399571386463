import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {addDays} from "date-fns";
import {Languages} from "../../../utilities/Constants";
import SaleEvent from "../../../classes/SaleEvent";
import {confirmAlert} from "react-confirm-alert";
import FileInput, {
    getBase64,
    imageTypes,
    saleEventCoverType
} from "../../../components/common/inputs/file-input/FileInput";
import PageTitle from "../../../components/common/page-title/PageTitle";
import RedButton from "../../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../../components/common/loading/LoadingOval";
import InsideContentForm from "../../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../../components/common/inputs/text-input/TextInput";
import Notice from "../../../components/common/notice/Notice";
import DividerFooter from "../../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../../components/common/footer/InsideContentFormFooter";
import Button from "../../../components/common/buttons/Button";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import TextArea from "../../../components/common/inputs/text-area/TextArea";
import DateInput from "../../../components/common/inputs/date-input/DateInput";
import SelectInput from "../../../components/common/inputs/select-input/SelectInput";
import FileUploader from "../../../helpers/FileUploader";

const EditSaleEvent = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [saleEvent, setSaleEvent] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [coverImageLocalFile, setCoverImageLocalFile] = useState(null);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(addDays(new Date(), 7));
    const [selectedLanguage, setSelectedLanguage] = useState(Languages[0]);

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchSaleEvent = async () => {
            try {
                setLoading(true);
                const saleEvent = await SaleEvent.getQuery().get(params.id)

                if (isMounted) {
                    setSaleEvent(saleEvent);
                    setTitle(saleEvent.getTitle());
                    setBody(saleEvent.getBody());
                    setStartDate(saleEvent.getStartDate());
                    setEndDate(saleEvent.getEndDate());
                    setSelectedLanguage(saleEvent.getLanguage())
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchSaleEvent();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        saveSaleEvent(saleEvent, title, body, startDate, endDate, selectedLanguage.key, coverImageLocalFile, (coverImageLocalFile != null));
    };

    const confirmDelete = (saleEvent) => {
        if (saleEvent != null) {
            confirmAlert({
                title: 'Delete sale event',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteSaleEvent(saleEvent)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const saveSaleEvent = async (saleEvent, title, body, startDate,
                                 endDate, languageCode, file, fileWasChanged) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            let pSaleEvent = saleEvent

            if (saleEvent == null) {
                pSaleEvent = new SaleEvent();
            }

            pSaleEvent.setTitle(title);
            pSaleEvent.setBody(body);
            pSaleEvent.setStartDate(startDate);
            pSaleEvent.setEndDate(endDate);
            pSaleEvent.setLanguageCode(languageCode)

            if (fileWasChanged) {
                const coverImageFile = await FileUploader.uploadFile(file, "sale-event-image-cover", file["type"]);
                pSaleEvent.setImageFile(coverImageFile);
            }

            pSaleEvent.setEnabled(true);
            await pSaleEvent.save();

            setSuccess(true);
            if (saleEvent === null) {
                cleanValues();
            }
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
        }
    }

    const deleteSaleEvent = async (saleEvent) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            saleEvent.setEnabled(false);

            await saleEvent.save();

            navigate("/sale-events");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (saleEvent === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setSaleEvent(null);
        setCoverImageLocalFile(null)
        setTitle('');
        setBody('');
        setStartDate(new Date());
        setEndDate(addDays(new Date(), 7));
        setSelectedLanguage(Languages[0]);
    }

    const handleFileInputChange = async (e) => {
        const file = e.target.files[0];
        try {
            file["base64"] = await getBase64(file);
            setCoverImageLocalFile(file);
        }
        catch (error) {
            console.log(error);
        }
    };

    const startDateChangeHandler = (e) => {
        setStartDate(e)
    }

    const endDateChangeHandler = (e) => {
        setEndDate(e)
    }

    const languageSelectChangeHandler = (e) => {
        setSelectedLanguage(e);
    }

    return (
        <div className="EditSaleEvent">
            <PageTitle title="Sale event"
                       brief="Add a sale event" >
                { saleEvent != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(saleEvent)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="sale-event-cover-image"
                                                             label="Cover image">
                                            <FileInput name="sale-event-cover-image"
                                                       title="Select a cover image"
                                                       accept={imageTypes}
                                                       previewImage={
                                                           coverImageLocalFile !== null ?
                                                               coverImageLocalFile["base64"] :
                                                               saleEvent !== null ?
                                                                   saleEvent.getImageUrl() :
                                                                   null }
                                                       type={saleEventCoverType}
                                                       onChange={handleFileInputChange}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="sale-event-title"
                                                             label="Title">
                                            <TextInput id="sale-event-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="sale-event-body"
                                                             label="Description">
                                            <TextArea id="sale-event-body"
                                                      value={body}
                                                      rows={8}
                                                      required={true}
                                                      autoComplete={false}
                                                      onChange={(e) => setBody(e.target.value)} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="sale-event-start-date"
                                                             label="Launch date">
                                            <DateInput id="sale-event-start-date"
                                                       value={startDate}
                                                       required={true}
                                                       onChange={startDateChangeHandler} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="sale-event-end-date"
                                                             label="End date">
                                            <DateInput id="sale-event-end-date"
                                                       value={endDate}
                                                       required={true}
                                                       onChange={endDateChangeHandler} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="sale-event-language-code"
                                                             label={"Language"}>
                                            <SelectInput name="sale-event-language-code"
                                                         options={Languages}
                                                         preSelectedOption={selectedLanguage}
                                                         onChange={languageSelectChangeHandler}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The sale event was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditSaleEvent;