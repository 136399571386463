import Parse from "parse";

class PostSection extends Parse.Object {

    constructor() {
        super('PostSection');
    }

    getSubtitle() {
        return this.get('subtitle');
    }

    setSubtitle(subtitle) {
        this.set('subtitle', subtitle)
    }

    getBody() {
        return this.get('body');
    }

    setBody(body) {
        this.set('body', body)
    }

    getOrder() {
        return this.get('order');
    }

    setOrder(order) {
        this.set('order', order)
    }

    getPhotoUrl() {
        if (this.get("photo") !== undefined && this.get("photo") != null) {
            return this.get("photo").url()
        }
        return null;
    }

    setPhotoFile(file) {
        this.set("photo", file);
    }

    getPhotoWidth() {
        return this.get('photoWidth');
    }

    setPhotoWidth(photoWidth) {
        this.set('photoWidth', photoWidth)
    }

    getPhotoHeight() {
        return this.get('photoHeight');
    }

    setPhotoHeight(photoHeight) {
        this.set('photoHeight', photoHeight)
    }

    setPost(post) {
        this.set('post', post);
        this.set('postId', post.id);
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    static getQuery(postId) {
        const parseQuery = new Parse.Query(PostSection);
        parseQuery.equalTo("enabled", true);
        parseQuery.equalTo("postId", postId);
        parseQuery.ascending("order");
        return parseQuery;
    }
}

export default PostSection;