import Parse from "parse";

class ProductImage extends Parse.Object {

    constructor() {
        super('ProductImage');
    }

    getImageUrl() {
        return this.get("image").url();
    }

    setImage(image) {
        this.set('image', image);
    }

    setProduct(product) {
        this.set('product', product);
        this.set('productId', product.id);
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    static getQuery(productId) {
        const parseQuery = new Parse.Query(ProductImage);
        parseQuery.equalTo("enabled", true);
        parseQuery.equalTo("productId", productId);
        return parseQuery;
    }
}

export default ProductImage;