import React from 'react';

import "./form-error-footer.scss"

const FormErrorFooter = ({errorMessage, innerRef}) => {
    return (
        <div className={(errorMessage !== undefined && errorMessage !== "") ? "FormErrorFooter" : "offscreen" }>
            <p ref={innerRef} className="FormErrorFooter__text">
                {errorMessage}
            </p>
        </div>
    );
};

export default FormErrorFooter;