import React, {useEffect, useState} from 'react';
import SaleEvent from "../../../classes/SaleEvent";
import PageTitle from "../../../components/common/page-title/PageTitle";
import {Link} from "react-router-dom";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import {FiPlus} from "react-icons/fi";
import SaleEventsTable from "../../../components/tables/SaleEventsTable";
import SectionTitle from "../../../components/common/section-title/SectionTitle";

const SaleEvents = () => {

    const [activeSaleEvents, setActiveSaleEvents] = useState([]);
    const [upcomingSaleEvents, setUpcomingSaleEvents] = useState([]);
    const [pastSaleEvents, setPastSaleEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchActiveSaleEvents = async () => {
            try {
                const results = await SaleEvent.getActiveQuery().find();
                if (isMounted) {
                    setActiveSaleEvents(results);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchActiveSaleEvents();

        const fetchUpcomingSaleEvents = async () => {
            try {
                const results = await SaleEvent.getUpcomingQuery().find();
                if (isMounted) {
                    setUpcomingSaleEvents(results);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchUpcomingSaleEvents();

        const fetchPastSaleEvents = async () => {
            try {
                const results = await SaleEvent.getPastQuery().find();
                if (isMounted) {
                    setPastSaleEvents(results);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchPastSaleEvents();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, [])

    return (
        <div>
            <PageTitle title="Sale events">
                <Link to="./new">
                    <PrimaryButton title="New sale event" icon={<FiPlus/>}/>
                </Link>
            </PageTitle>
            <SectionTitle title="Active" />
            <SaleEventsTable items={activeSaleEvents} loading={loading} />
            <SectionTitle title="Upcoming" />
            <SaleEventsTable items={upcomingSaleEvents} loading={loading} />
            <SectionTitle title="Past" />
            <SaleEventsTable items={pastSaleEvents} loading={loading} />
        </div>
    );
};

export default SaleEvents;