import Parse from "parse";
import {Languages} from "../utilities/Constants";

class Podcast extends Parse.Object {

    constructor() {
        super('Podcast');
    }

    getTitle() {
        return this.get('title');
    }

    setTitle(title) {
        this.set('title', title)
    }

    getTagline() {
        return this.get('tagline');
    }

    setTagline(tagline) {
        this.set('tagline', tagline)
    }

    getBody() {
        return this.get('body');
    }

    setBody(body) {
        this.set('body', body)
    }

    getDurationInSeconds() {
        return this.get('durationInSeconds');
    }

    setDurationInSeconds(seconds) {
        this.set('durationInSeconds', seconds)
    }

    getDuration() {
        return this.get('duration');
    }

    setDuration(duration) {
        this.set('duration', duration)
    }

    getFileName() {
        return this.get('fileName');
    }

    setFileName(fileName) {
        this.set('fileName', fileName)
    }

    getInterviewVideoURL() {
        return this.get('interviewVideoUrl');
    }

    setInterviewVideoURL(videoURL) {
        this.set('interviewVideoUrl', videoURL)
    }

    getAudioUrl() {
        return this.get("audio").url()
    }

    setAudioFile(audioFile) {
        this.set('audio', audioFile)
    }

    getImageUrl() {
        return this.get("coverPhoto").url()
    }

    setCoverPhotoFile(file) {
        this.set("coverPhoto", file);
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    getCategoryTitle() {
        return this.get("category").getTitle();
    }

    getCategory() {
        return this.get("category");
    }

    setCategory(category) {
        this.set('category', category);
        this.set('categoryId', category.id);
    }

    getLanguage() {
        const languageCode = this.get('languageCodeString');
        return Languages.filter(option => option.key === languageCode)[0]
    }

    setLanguageCode(code) {
        this.set('languageCodeString', code);
    }

    static getQuery() {
        const parseQuery = new Parse.Query(Podcast);
        parseQuery.equalTo("enabled", true);
        parseQuery.ascending("title");
        parseQuery.include("category");
        return parseQuery;
    }

    static getFilteredQuery(languageCode, categoryId, skip, limit) {
        const parseQuery = new Parse.Query(Podcast);
        parseQuery.equalTo("enabled", true);
        parseQuery.include("category");
        parseQuery.skip(skip);
        parseQuery.limit(limit);
        parseQuery.descending("title")
        parseQuery.withCount()
        if (languageCode != null) {
            parseQuery.equalTo("languageCodeString", languageCode);
        }
        if (categoryId != null) {
            parseQuery.equalTo("categoryId", categoryId);
        }
        return parseQuery;
    }
}

export default Podcast;