import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import FileUploader from "../../../../helpers/FileUploader";
import DocumentariesCategory from "../../../../classes/DocumentariesCategory";
import FileInput, {
    ecoCategoryCoverType,
    getBase64,
    imageTypes,
} from "../../../../components/common/inputs/file-input/FileInput";
import PageTitle from "../../../../components/common/page-title/PageTitle";
import RedButton from "../../../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../../../components/common/loading/LoadingOval";
import InsideContentForm from "../../../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../../../components/common/inputs/text-input/TextInput";
import Notice from "../../../../components/common/notice/Notice";
import DividerFooter from "../../../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../../../components/common/footer/InsideContentFormFooter";
import Button from "../../../../components/common/buttons/Button";
import PrimaryButton from "../../../../components/common/buttons/PrimaryButton";

const EditDocumentariesCategory = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [documentariesCategory, setDocumentariesCategory] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [coverImageLocalFile, setCoverImageLocalFile] = useState(null);
    const [title, setTitle] = useState('');
    const [spanishTitle, setSpanishTitle] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        saveDocumentariesCategory(documentariesCategory, title, spanishTitle, coverImageLocalFile, (coverImageLocalFile != null));
    };

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchDocumentariesCategory = async () => {
            try {
                setLoading(true);
                const documentariesCategory = await DocumentariesCategory.getPickableQuery().get(params.id)

                if (isMounted) {
                    setDocumentariesCategory(documentariesCategory);
                    setTitle(documentariesCategory.getTitle());
                    setSpanishTitle(documentariesCategory.getSpanishTitle());
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchDocumentariesCategory();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const confirmDelete = (documentariesCategory) => {
        if (documentariesCategory != null) {
            confirmAlert({
                title: 'Delete documentaries category',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteDocumentariesCategory(documentariesCategory)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const deleteDocumentariesCategory = async (documentariesCategory) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            documentariesCategory.setEnabled(false);

            await documentariesCategory.save();

            navigate("/documentaries-categories");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (documentariesCategory === null) {
                cleanValues();
            }
        }
    }

    const saveDocumentariesCategory = async (documentariesCategory,
                                             title,
                                             spanishTitle,
                                             file,
                                             fileWasChanged) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            let pDocumentariesCategory = documentariesCategory

            if (documentariesCategory == null) {
                pDocumentariesCategory = new DocumentariesCategory();
            }

            pDocumentariesCategory.setTitle(title);
            pDocumentariesCategory.setSpanishTitle(spanishTitle);

            if (fileWasChanged) {
                const coverImageFile = await FileUploader.uploadFile(file, "documentaries_category_cover_photo",
                    file["type"]);
                pDocumentariesCategory.setCoverPhotoFile(coverImageFile);
            }

            pDocumentariesCategory.setPickable(true);
            pDocumentariesCategory.setEditable(true);
            pDocumentariesCategory.setEnabled(true);
            await pDocumentariesCategory.save();

            setSuccess(true);
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (documentariesCategory === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setDocumentariesCategory(null);
        setTitle('');
        setSpanishTitle('');
        setCoverImageLocalFile(null);
    }

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];

        getBase64(file)
            .then(result => {
                file["base64"] = result;
                setCoverImageLocalFile(file);
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <div className="DocumentariesCategory">
            <PageTitle title="Documentaries category"
                       brief="Add a documentaries category" >
                { documentariesCategory != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(documentariesCategory)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="documentaries-category-cover-image"
                                                             label="Cover image">
                                            <FileInput name="documentaries-category-cover-image"
                                                       title="Select a cover image"
                                                       accept={imageTypes}
                                                       previewImage={
                                                           coverImageLocalFile !== null ?
                                                               coverImageLocalFile["base64"] :
                                                               documentariesCategory !== null ?
                                                                   documentariesCategory.getCoverPhotoUrl() :
                                                                   null }
                                                       type={ecoCategoryCoverType}
                                                       onChange={handleFileInputChange}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="documentaries-category-title"
                                                             label="Title">
                                            <TextInput id="documentaries-category-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="documentaries-category-spanish-title"
                                                             label="Spanish title">
                                            <TextInput id="documentaries-category-spanish-title"
                                                       value={spanishTitle}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setSpanishTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The documentaries category was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditDocumentariesCategory;