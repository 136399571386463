import React, {useEffect, useState} from 'react';
import PageTitle from "../../../components/common/page-title/PageTitle";
import {Link} from "react-router-dom";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import {FiPlus} from "react-icons/fi";
import MusicPlaylist from "../../../classes/MusicPlaylist";
import MusicPlaylistsTable from "../../../components/tables/MusicPlaylistsTable";

const MusicPlaylists = () => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchMusicPlaylists = async () => {
            try {
                const results = await MusicPlaylist.getQuery().find();
                if (isMounted) {
                    setItems(results);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchMusicPlaylists();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    return (
        <div>
            <PageTitle title="Music playlists">
                <Link to="./new">
                    <PrimaryButton title="New playlist" icon={<FiPlus/>}/>
                </Link>
            </PageTitle>
            <MusicPlaylistsTable items={items} loading={loading} />
        </div>
    );
};

export default MusicPlaylists;