import Parse from "parse";
import {Navigate, Outlet, useLocation} from "react-router-dom";

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation();
    const currentUser = Parse.User.current();

    return (
        currentUser && currentUser.get("tempRole") === "super-admin" ?
            <Outlet />
            :
            <Navigate to="/login" state={ {from: location} } replace />
        // currentUser?.roles?.find(role => allowedRoles?.includes(role))
        //     ?
        //     <Outlet />
        //     :
        //     (auth?.token)
        //         ?
        //         <Navigate to="/unauthorized" state={ {from: location} } replace />
        //         :
        //         <Navigate to="/login" state={ {from: location} } replace />
    );
}

export default RequireAuth;