import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Languages} from "../../utilities/Constants";
import FileUploader from "../../helpers/FileUploader";
import {confirmAlert} from "react-confirm-alert";
import PageTitle from "../../components/common/page-title/PageTitle";
import RedButton from "../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../components/common/loading/LoadingOval";
import InsideContentForm from "../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../components/common/inputs/text-input/TextInput";
import TextArea from "../../components/common/inputs/text-area/TextArea";
import SelectInput from "../../components/common/inputs/select-input/SelectInput";
import Notice from "../../components/common/notice/Notice";
import DividerFooter from "../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../components/common/footer/InsideContentFormFooter";
import Button from "../../components/common/buttons/Button";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import FileInput, {
    audioTypes,
    getBase64,
    imageTypes,
    podcastCoverType
} from "../../components/common/inputs/file-input/FileInput";
import Podcast from "../../classes/Podcast";
import Category from "../../classes/Category";
import moment from 'moment';
import AudioInput from "../../components/common/inputs/audio-input/AudioInput";

const EditPodcast = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [podcast, setPodcast] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [coverImageLocalFile, setCoverImageLocalFile] = useState(null);

    const [audioLocalFile, setAudioLocalFile] = useState(null);
    const [audioFileName, setAudioFileName] = useState(null);
    const [audioDurationInSeconds, setAudioDurationInSeconds] = useState(null);
    const [audioDurationString, setAudioDurationString] = useState(null);

    const [title, setTitle] = useState('');
    const [tagline, setTagline] = useState('');
    const [body, setBody] = useState('');
    const [interviewVideoUrl, setInterviewVideoUrl] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState(Languages[0]);
    const [podcastCategories, setPodcastCategories] = useState([]);
    const [podcastCategoriesKeyValue, setPodcastCategoriesKeyValue] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        savePodcast(podcast, title, tagline, body, interviewVideoUrl, selectedLanguage.key, selectedCategory,
            coverImageLocalFile, (coverImageLocalFile != null),
            audioLocalFile, (audioLocalFile != null),
            audioDurationInSeconds, audioDurationString);
    };

    const savePodcast = async (podcast,
                               title,
                               tagline,
                               body,
                               interviewVideoUrl,
                               language,
                               selectedCategory,
                               coverPhotoFile,
                               coverPhotoFileWasChanged,
                               audioFile,
                               audioFileWasChanged,
                               durationInSeconds,
                               durationString) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            let pPodcast = podcast

            if (podcast == null) {
                pPodcast = new Podcast();
            }

            pPodcast.setTitle(title);
            pPodcast.setTagline(tagline);
            pPodcast.setBody(body);
            pPodcast.setInterviewVideoURL(interviewVideoUrl);
            pPodcast.setCategory(selectedCategory);
            pPodcast.setLanguageCode(language);

            if (coverPhotoFileWasChanged) {
                const coverImageFile = await FileUploader.uploadFile(coverPhotoFile, "podcast_cover_photo",
                    coverPhotoFile["type"]);
                pPodcast.setCoverPhotoFile(coverImageFile);
            }

            if (audioFileWasChanged) {
                const audioFile = await FileUploader.uploadFile(audioLocalFile, "podcast_audio",
                    audioLocalFile["type"]);
                pPodcast.setAudioFile(audioFile);
                pPodcast.setDuration(durationString);
                pPodcast.setDurationInSeconds(durationInSeconds);
                pPodcast.setFileName(audioFileName);
            }

            pPodcast.setEnabled(true);
            await pPodcast.save();

            setSuccess(true);
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (podcast === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setPodcast(null);
        setTitle('');
        setTagline('');
        setBody('');
        setInterviewVideoUrl('');
        setAudioLocalFile(null);
        setAudioFileName(null);
        setAudioDurationInSeconds(null);
        setAudioDurationString(null);
        setCoverImageLocalFile(null);
        setSelectedLanguage(Languages[0]);
    }

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchPodcast = async () => {
            try {
                setLoading(true);
                const podcast = await Podcast.getQuery().get(params.id)

                if (isMounted) {
                    setPodcast(podcast);
                    setTitle(podcast.getTitle());
                    setTagline(podcast.getTagline());
                    setBody(podcast.getBody());
                    setInterviewVideoUrl(podcast.getInterviewVideoURL());
                    setAudioFileName(podcast.getFileName());
                    setAudioDurationString(podcast.getDuration());
                    setAudioDurationInSeconds(podcast.getDurationInSeconds());
                    setSelectedLanguage(podcast.getLanguage());
                    setSelectedCategory(podcast.getCategory());
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchPodcast();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchPodcastsCategory = async () => {
            try {
                const podcastsCategories = await Category.getPickableQuery().find();
                if (isMounted) {
                    const mappedResults = podcastsCategories.map(function(item) { return {
                        key: item.id,
                        value: item.getTitle()
                    }});
                    setPodcastCategories(podcastsCategories);
                    setPodcastCategoriesKeyValue(mappedResults);
                    if (params.id === undefined) {
                        setSelectedCategory(podcastsCategories[0]);
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchPodcastsCategory();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const confirmDelete = (podcast) => {
        if (podcast != null) {
            confirmAlert({
                title: 'Delete podcast',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deletePodcast(podcast)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const deletePodcast = async (podcast) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            podcast.setEnabled(false);

            await podcast.save();

            navigate("/podcasts");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (podcast === null) {
                cleanValues();
            }
        }
    }

    const languageSelectChangeHandler = (e) => {
        setSelectedLanguage(e);
    }

    const podcastsCategorySelectChangeHandler = (e) => {
        const podcastCategory = podcastCategories.filter(option => option.id === e.key)[0]
        setSelectedCategory(podcastCategory);
    }

    const handleCoverImageFileInputChange = async (e) => {
        const file = e.target.files[0];
        try {
            file["base64"] = await getBase64(file);
            setCoverImageLocalFile(file);
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleAudioFileInputChange = async (e) => {
        const file = e.target.files[0];
        try {
            file["base64"] = await getBase64(file);
            setAudioFileName(file["name"]);
            setAudioLocalFile(file);
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleAudioMetadataChange = (e) => {
        const seconds = e.currentTarget.duration;
        const duration =  moment.duration(seconds, "seconds");

        let time = "";
        const hours = duration.hours();
        if (hours > 0) { time = hours + ":" ; }

        time = time + duration.minutes() + ":" + duration.seconds();

        setAudioDurationInSeconds(seconds);
        setAudioDurationString(time);
    };

    return (
        <div className="EditPodcast">
            <PageTitle title="Podcast"
                       brief="Add a podcast" >
                { podcast != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(podcast)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="podcast-cover-image"
                                                             label="Cover image">
                                            <FileInput name="podcast-cover-image"
                                                       title="Select a cover image"
                                                       accept={imageTypes}
                                                       previewImage={
                                                           coverImageLocalFile !== null ?
                                                               coverImageLocalFile["base64"] :
                                                               podcast !== null ?
                                                                   podcast.getImageUrl() :
                                                                   null }
                                                       type={podcastCoverType}
                                                       onChange={handleCoverImageFileInputChange}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="podcast-title"
                                                             label="Title">
                                            <TextInput id="podcast-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="podcast-tagline"
                                                             label="Episode number / Tagline">
                                            <TextInput id="podcast-tagline"
                                                       value={tagline}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTagline(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="podcast-body"
                                                             label="Description">
                                            <TextArea id="podcast-body"
                                                      value={body}
                                                      rows={8}
                                                      required={true}
                                                      autoComplete={false}
                                                      onChange={(e) => setBody(e.target.value)} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="podcast-url"
                                                             label="Interview video URL">
                                            <TextInput id="podcast-url"
                                                       value={interviewVideoUrl}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setInterviewVideoUrl(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                {podcastCategoriesKeyValue && podcastCategoriesKeyValue.length > 0 && selectedCategory != null &&
                                    <Row gutterWidth={20}>
                                        <Col>
                                            <LeftAlignLabelField id="podcast-category"
                                                                 label={"Category"}>
                                                <SelectInput name="podcast-category"
                                                             options={podcastCategoriesKeyValue}
                                                             preSelectedOption={{
                                                                 key: selectedCategory.id,
                                                                 value: selectedCategory.getTitle()
                                                             }}
                                                             onChange={podcastsCategorySelectChangeHandler}/>
                                            </LeftAlignLabelField>
                                        </Col>
                                    </Row>
                                }
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="podcast-language-code"
                                                             label={"Language"}>
                                            <SelectInput name="podcast-language-code"
                                                         options={Languages}
                                                         preSelectedOption={selectedLanguage}
                                                         onChange={languageSelectChangeHandler}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="podcast-audio"
                                                             label="Audio"
                                                             brief="Max size 60 MB">
                                            <AudioInput title={audioFileName}
                                                        name="podcast-audio"
                                                        accept={audioTypes}
                                                        src={
                                                audioLocalFile ?
                                                    audioLocalFile["base64"] :
                                                    podcast ? podcast.getAudioUrl()
                                                        : null }
                                                        onChange={handleAudioFileInputChange}
                                                        onAudioMetadataChange={handleAudioMetadataChange}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>

                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The podcast was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditPodcast;