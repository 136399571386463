import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import MusicPlaylist from "../../../classes/MusicPlaylist";
import {confirmAlert} from "react-confirm-alert";
import PageTitle from "../../../components/common/page-title/PageTitle";
import RedButton from "../../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../../components/common/loading/LoadingOval";
import InsideContentForm from "../../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../../components/common/inputs/text-input/TextInput";
import TextArea from "../../../components/common/inputs/text-area/TextArea";
import Notice from "../../../components/common/notice/Notice";
import DividerFooter from "../../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../../components/common/footer/InsideContentFormFooter";
import Button from "../../../components/common/buttons/Button";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";

const EditMusicPlaylist = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [playlist, setPlaylist] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        saveMusicPlaylist(playlist, title, description, url);
    };

    const saveMusicPlaylist = async (playlist, title, description, url) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            let pPlaylist = playlist

            if (playlist == null) {
                pPlaylist = new MusicPlaylist();
            }

            pPlaylist.setTitle(title);
            pPlaylist.setDescriptionString(description);
            pPlaylist.setUrl(url)
            pPlaylist.setEnabled(true);

            await pPlaylist.save();

            setSuccess(true);
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (playlist === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setPlaylist(null);
        setTitle('');
        setDescription('');
        setUrl('');
    }

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchMusicPlaylist = async () => {
            try {
                setLoading(true);
                const playlist = await MusicPlaylist.getQuery().get(params.id)

                if (isMounted) {
                    setPlaylist(playlist);
                    setTitle(playlist.getTitle());
                    setDescription(playlist.getDescriptionString());
                    setUrl(playlist.getUrl());
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchMusicPlaylist();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const confirmDelete = (musicPlaylist) => {
        if (musicPlaylist != null) {
            confirmAlert({
                title: 'Delete music playlist',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteMusicPlaylist(musicPlaylist)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const deleteMusicPlaylist = async (musicPlaylist) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            musicPlaylist.setEnabled(false);

            await musicPlaylist.save();

            navigate("/music-playlists");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (musicPlaylist === null) {
                cleanValues();
            }
        }
    }

    return (
        <div className="EditMusicPlaylist">
            <PageTitle title="Music playlist"
                       brief="Add a music playlist" >
                { playlist != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(playlist)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="music-playlist-title"
                                                             label="Title">
                                            <TextInput id="music-playlist-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="music-playlist-description"
                                                             label="Description">
                                            <TextArea id="music-playlist-description"
                                                      value={description}
                                                      rows={8}
                                                      required={true}
                                                      autoComplete={false}
                                                      onChange={(e) => setDescription(e.target.value)} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="music-playlist-url"
                                                             label="URL">
                                            <TextInput id="music-playlist-url"
                                                       value={url}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setUrl(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The music playlist was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditMusicPlaylist;