import React from 'react';
import {useNavigate} from "react-router-dom";
import LoadingContainer from "../common/loading/LoadingContainer";
import LoadingOval from "../common/loading/LoadingOval";

const EcoCategoriesTable = ({items, loading}) => {

    const navigate = useNavigate();

    const handleTableRowClick = (documentariesCategory) => {
        if (!documentariesCategory.isEditable()) {
            return;
        }
        navigate("./" + documentariesCategory.id + "/edit")
    }

    return (
        <div>
            <table className="Table">
                <thead>
                <tr>
                    <td className="Table__header-data">
                        <div className="Table__data-container">
                            <span>
                                Image
                            </span>
                        </div>
                    </td>
                    <td className="Table__header-data">
                        <div className="Table__data-container">
                            <span>
                                Title
                            </span>
                        </div>
                    </td>
                    <td className="Table__header-data">
                        <div className="Table__data-container">
                            <span>
                                Spanish title
                            </span>
                        </div>
                    </td>
                </tr>
                </thead>
                { loading === false &&
                    <tbody>
                    { items && items.map(item =>
                        <tr className="Table__row" key={item.id} onClick={ () => handleTableRowClick(item) } >
                            <td className="Table__body-data Table__body-data__image Table__documentaries-category-body-data__image">
                                <div className="Table__data-container">
                                    <div className="Table__image-container Table__documentaries-category-cover-photo-container">
                                        <img className="Table__image Table__documentaries-category-cover-photo" src={ item.getCoverPhotoUrl() } />
                                        <div className="Table_image-overlay"/>
                                    </div>
                                </div>
                            </td>
                            <td className="Table__body-data">
                                <div className="Table__data-container">
                            <span className="Table__primary-text-span">
                                { item.getTitle() }
                            </span>
                                </div>
                            </td>
                            <td className="Table__body-data">
                                <div className="Table__data-container">
                            <span className="Table__secondary-text-span Table__two-lines-span">
                                { item.getSpanishTitle() }
                            </span>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                }
            </table>
            { loading === true &&
                <LoadingContainer>
                    <LoadingOval loading={loading}/>
                </LoadingContainer>
            }
        </div>
    );
};

export default EcoCategoriesTable;