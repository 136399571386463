import React from 'react';

import "./text-area.scss"

const TextArea = ({id, value, rows, placeholder, innerRef, onChange, onBlur, onFocus, autoComplete, disabled, required}) => {

    const handleOnChange = (e) => {
        // Reset field height
        e.target.style.height = 'inherit';

        // Get the computed styles for the element
        // const computed = window.getComputedStyle(e.target);

        // Calculate the height
        const height =
            // parseInt(computed.getPropertyValue('border-top-width'))
            // + parseInt(computed.getPropertyValue('padding-top'))
            e.target.scrollHeight
            // + parseInt(computed.getPropertyValue('padding-bottom'))
            // + parseInt(computed.getPropertyValue('border-bottom-width'));

        e.target.style.height = `${height}px`;
        onChange(e);
    }

    return (
        <textarea id={id}
                  value={value}
                  placeholder={placeholder}
                  rows={rows}
                  ref={innerRef}
                  className="TextArea"
                  autoComplete={ (autoComplete !== undefined && autoComplete === true) ? "on" : "off" }
                  onChange={handleOnChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  disabled={disabled}
                  required={required}
        />
    );
};

export default TextArea;