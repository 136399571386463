import React from "react";

import "./top-bar.scss"
import TextButton from "../common/buttons/TextButton";
import {useNavigate} from "react-router-dom";
import Parse from "parse";
import {confirmAlert} from "react-confirm-alert";

const TopBar = () => {

    const navigate = useNavigate();

    const confirmLogOut = () => {
            confirmAlert({
                title: 'Confirm',
                message: 'Do you want to log out?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => handleLogOut()
                    },
                    {
                        label: 'No',
                    }
                ]
            });
    }

    const handleLogOut = () => {
        Parse.User.logOut().then(() => {
            navigate("/login", { replace: true });
        });
    }

    return (
        <div className="TopBar divider-bottom-1">
            <div className="TopBar__wrapper">
                <div className="TopBar__buttons-container">
                    <TextButton text="Log out" onClick={confirmLogOut} />
                </div>
            </div>
        </div>
    );
};

export default TopBar;