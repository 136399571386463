import React, {useEffect, useState} from 'react';
import {Languages} from "../../utilities/Constants";
import VideoCategory from "../../classes/VideoCategory";
import Video from "../../classes/Video";
import PageTitle from "../../components/common/page-title/PageTitle";
import {Link} from "react-router-dom";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import {FiPlus} from "react-icons/fi";
import FilterHeader from "../../components/common/header/FilterHeader";
import {Col, Container, Row} from "react-grid-system";
import SelectInput from "../../components/common/inputs/select-input/SelectInput";
import PaginationTableFooter from "../../components/common/footer/PaginationTableFooter";
import VideosTable from "../../components/tables/VideosTable";

const Videos = () => {

    const allCategory = {key: "-1", value: "All"};

    let limit = 15;

    const [videos, setVideos] = useState([]);
    const [videoCategories, setVideoCategories] = useState([allCategory]);
    const [loading, setLoading] = useState(true);

    const [selectedLanguage, setSelectedLanguage] = useState(Languages[0]);
    const [selectedCategory, setSelectedCategory] = useState(videoCategories[0]);

    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [count, setCount] = useState(0);
    const [skip, setSkip] = useState(0);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchVideoCategories = async () => {
            try {
                const results = await VideoCategory.getQuery().find();
                if (isMounted) {
                    const mappedResults = [allCategory];
                    mappedResults.push(...results.map(function(item) { return {
                        key: item.id,
                        value: item.getTitle()
                    }}));
                    setVideoCategories(mappedResults);
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchVideoCategories();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchVideos = async () => {
            try {
                setLoading(true);
                const response = await Video.getFilteredQuery(
                    selectedLanguage.key,
                    selectedCategory.key === "-1" ? null : selectedCategory.key,
                    skip,
                    limit
                ).find();
                if (isMounted) {
                    setCount(response.count)
                    setNumberOfPages(Math.ceil(response.count / limit))
                    if (response.count === 0) {
                        setCurrentPage(0)
                    }
                    else {
                        setCurrentPage((skip + limit) / limit)
                    }
                    setVideos(response.results);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchVideos();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, [selectedLanguage, selectedCategory, skip]);

    const languageSelectChangeHandler = (e) => {
        setSelectedLanguage(e);
        setSkip(0);
    }

    const shopCategorySelectChangeHandler = (e) => {
        setSelectedCategory(e);
        setSkip(0);
    }

    return (
        <div className="Content-bottom-margin">
            <PageTitle title="Videos" brief={
                (selectedCategory ? selectedCategory.value : "All") + " • " + selectedLanguage.value
            }>
                <Link to="./new">
                    <PrimaryButton title="New video" icon={<FiPlus/>}/>
                </Link>
            </PageTitle>
            <FilterHeader>
                <Container fluid>
                    <Row gutterWidth={20} >
                        <Col sm={12} md={7}>
                            { <SelectInput name="videos-categories"
                                           options={videoCategories}
                                           preSelectedOption={selectedCategory}
                                           onChange={shopCategorySelectChangeHandler}/>
                            }
                        </Col>
                        <Col sm={12} md={3}>
                            <SelectInput name="videos-language-code"
                                         options={Languages}
                                         preSelectedOption={selectedLanguage}
                                         onChange={languageSelectChangeHandler}/>
                        </Col>
                    </Row>
                </Container>
            </FilterHeader>
            <VideosTable items={videos} loading={loading} footer={
                <PaginationTableFooter loading={loading}
                                       currentPage={currentPage}
                                       numberOfPages={ numberOfPages }
                                       onPrevious={ () => { setSkip(skip - limit) } }
                                       onNext={ () => { setSkip(skip + limit) } } >
                </PaginationTableFooter>
            } />
        </div>
    );
};

export default Videos;