import './App.scss';
import {ScreenClassProvider, setConfiguration} from 'react-grid-system';
import { initializeParse } from '@parse/react';
import {Outlet, Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import SideBar from "./components/side-bar/SideBar";
import TopBar from "./components/top-bar/TopBar";
import LogIn from "./pages/login/LogIn";
import EditEcoDictionary from "./pages/eco/dictionary/EditEcoDictionary";
import EcoDictionaryEntries from "./pages/eco/dictionary/EcoDictionaryEntries";
import ShopCategories from "./pages/shop/shop-categories/ShopCategories";
import EditShopCategory from "./pages/shop/shop-categories/EditShopCategory";
import SaleEvents from "./pages/shop/sale-events/SaleEvents";
import EditSaleEvent from "./pages/shop/sale-events/EditSaleEvent";
import Products from "./pages/shop/products/Products";
import EditProduct from "./pages/shop/products/EditProduct";
import DocumentariesCategories from "./pages/eco/documentaries/documentaries-categories/DocumentariesCategories";
import EditDocumentariesCategory from "./pages/eco/documentaries/documentaries-categories/EditDocumentariesCategory";
import Documentaries from "./pages/eco/documentaries/Documentaries";
import EditDocumentary from "./pages/eco/documentaries/EditDocumentary";
import BooksCategories from "./pages/eco/books/books-categories/BooksCategories";
import EditBooksCategory from "./pages/eco/books/books-categories/EditBooksCategory";
import Books from "./pages/eco/books/Books";
import EditBook from "./pages/eco/books/EditBook";
import MusicPlaylists from "./pages/eco/music-playlists/MusicPlaylists";
import EditMusicPlaylist from "./pages/eco/music-playlists/EditMusicPlaylist";
import Videos from "./pages/videos/Videos";
import EditVideo from "./pages/videos/EditVideo";
import VideosCategories from "./pages/videos/videos-categories/VideosCategories";
import EditVideoCategory from "./pages/videos/videos-categories/EditVideoCategory";
import PostsCategories from "./pages/posts/posts-categories/PostsCategories";
import EditPostCategory from "./pages/posts/posts-categories/EditPostCategory";
import Podcasts from "./pages/podcasts/Podcasts";
import EditPodcast from "./pages/podcasts/EditPodcast";
import RequireAuth from "./components/RequireAuth";
import Posts from "./pages/posts/Posts";
import EditPost from "./pages/posts/EditPost";

initializeParse(
    'https://pg-app-q9lqwmlxzwbbh2v06irwuzq23ept5y.scalabl.cloud/1/',
    '7qEq6MyppoDlJtol1XFoyuJLPMPhSj5KJaMPMHOR',
    '4W8rWtYrpxajp61qU7a7yDVS5aRPNPJORHMRKLMY'
);

setConfiguration({ gutterWidth: 0 });

function App() {
    return (
        <ScreenClassProvider>
            <Routes>
                <Route path="/" element={<Layout/>}>

                    <Route path="/login" element={<LogIn/>} />
                    <Route path="/unauthorized" element={<h1>Unauthorized</h1>} />

                    <Route element={<RequireAuth allowedRoles={[]} />}>
                        <Route path="/" element={<SideBarLayout/>} >
                            <Route index element={<></>} />
                            <Route path="/posts">
                                <Route exact index element={<Posts/>}/>
                                <Route path="new"  element={<EditPost/>}/>
                                <Route path=":id/edit"  element={<EditPost/>}/>
                            </Route>
                            <Route path="/podcasts">
                                <Route exact index element={<Podcasts/>}/>
                                <Route path="new"  element={<EditPodcast/>}/>
                                <Route path=":id/edit"  element={<EditPodcast/>}/>
                            </Route>
                            <Route path="/eco-dictionary" >
                                <Route exact index  element={<EcoDictionaryEntries/>}/>
                                <Route path="new"  element={<EditEcoDictionary/>}/>
                                <Route path=":id/edit"  element={<EditEcoDictionary/>}/>
                            </Route>
                            <Route path="/shop-categories">
                                <Route exact index element={<ShopCategories/>}/>
                                <Route path="new"  element={<EditShopCategory/>}/>
                                <Route path=":id/edit"  element={<EditShopCategory/>}/>
                            </Route>
                            <Route path="/sale-events">
                                <Route exact index element={<SaleEvents/>}/>
                                <Route path="new"  element={<EditSaleEvent/>}/>
                                <Route path=":id/edit"  element={<EditSaleEvent/>}/>
                            </Route>
                            <Route path="/products">
                                <Route exact index element={<Products/>}/>
                                <Route path="new"  element={<EditProduct/>}/>
                                <Route path=":id/edit"  element={<EditProduct/>}/>
                            </Route>
                            <Route path="/documentaries-categories">
                                <Route exact index element={<DocumentariesCategories/>}/>
                                <Route path="new"  element={<EditDocumentariesCategory/>}/>
                                <Route path=":id/edit"  element={<EditDocumentariesCategory/>}/>
                            </Route>
                            <Route path="/documentaries">
                                <Route exact index element={<Documentaries/>}/>
                                <Route path="new"  element={<EditDocumentary/>}/>
                                <Route path=":id/edit"  element={<EditDocumentary/>}/>
                            </Route>
                            <Route path="/books-categories">
                                <Route exact index element={<BooksCategories/>}/>
                                <Route path="new"  element={<EditBooksCategory/>}/>
                                <Route path=":id/edit"  element={<EditBooksCategory/>}/>
                            </Route>
                            <Route path="/books">
                                <Route exact index element={<Books/>}/>
                                <Route path="new"  element={<EditBook/>}/>
                                <Route path=":id/edit"  element={<EditBook/>}/>
                            </Route>
                            <Route path="/music-playlists">
                                <Route exact index element={<MusicPlaylists/>}/>
                                <Route path="new"  element={<EditMusicPlaylist/>}/>
                                <Route path=":id/edit"  element={<EditMusicPlaylist/>}/>
                            </Route>
                            <Route path="/videos">
                                <Route exact index element={<Videos/>}/>
                                <Route path="new"  element={<EditVideo/>}/>
                                <Route path=":id/edit"  element={<EditVideo/>}/>
                            </Route>
                            <Route path="/videos-categories">
                                <Route exact index element={<VideosCategories/>}/>
                                <Route path="new"  element={<EditVideoCategory/>}/>
                                <Route path=":id/edit"  element={<EditVideoCategory/>}/>
                            </Route>
                            <Route path="/posts-categories">
                                <Route exact index element={<PostsCategories/>}/>
                                <Route path="new"  element={<EditPostCategory/>}/>
                                <Route path=":id/edit"  element={<EditPostCategory/>}/>
                            </Route>
                        </Route>
                    </Route>
                    {/* not found*/}
                    <Route path="*" element={<h1>Not found</h1>}/>

                </Route>
            </Routes>
        </ScreenClassProvider>
    );
}

const SideBarLayout = () => (
    <div className="Root">
        <SideBar />
        <div className="content">
            <TopBar />
            <div className="page-container">
                <Outlet/>
            </div>
        </div>
    </div>
)

export default App;
