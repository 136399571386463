import React from 'react';

import "./text-input.scss"

const TextInput = ({id, type, placeholder, value, innerRef, autoComplete, onChange, required, disabled, onFocus, onBlur}) => {

    return (
        <div className={"Field__input-container"}>
            <input className="Field__input"
                   id={id}
                   type={type !== undefined ? type : "text"}
                   value={value}
                   placeholder={placeholder}
                   ref={innerRef}
                   autoComplete={ (autoComplete !== undefined && autoComplete === true) ? "on" : "off" }
                   onChange={onChange}
                   onFocus={onFocus}
                   onBlur={onBlur}
                   disabled={disabled}
                   required={required}
            />
        </div>
    );
};

export default TextInput;