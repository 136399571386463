import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import DocumentariesCategory from "../../../classes/DocumentariesCategory";
import {confirmAlert} from "react-confirm-alert";
import FileInput, {
    ecoItemCoverType,
    getBase64,
    imageTypes
} from "../../../components/common/inputs/file-input/FileInput";
import PageTitle from "../../../components/common/page-title/PageTitle";
import RedButton from "../../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../../components/common/loading/LoadingOval";
import InsideContentForm from "../../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../../components/common/inputs/text-input/TextInput";
import Notice from "../../../components/common/notice/Notice";
import DividerFooter from "../../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../../components/common/footer/InsideContentFormFooter";
import Button from "../../../components/common/buttons/Button";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import TextArea from "../../../components/common/inputs/text-area/TextArea";
import Documentary from "../../../classes/Documentary";
import SelectInput from "../../../components/common/inputs/select-input/SelectInput";
import FileUploader from "../../../helpers/FileUploader";

const EditDocumentary = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [documentary, setDocumentary] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [coverImageLocalFile, setCoverImageLocalFile] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [trailerUrl, setTrailerUrl] = useState('');
    const [documentariesCategories, setDocumentariesCategories] = useState([]);
    const [documentariesCategoriesKeyValue, setDocumentariesCategoriesKeyValue] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        saveDocumentary(documentary, title, description, url, trailerUrl, selectedCategory, coverImageLocalFile, (coverImageLocalFile != null));
    };

    const saveDocumentary = async (documentary,
                                   title,
                                   description,
                                   url,
                                   trailerUrl,
                                   selectedCategory,
                                   file,
                                   fileWasChanged) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            let pDocumentary = documentary

            if (documentary == null) {
                pDocumentary = new Documentary();
            }

            pDocumentary.setTitle(title);
            pDocumentary.setDescriptionString(description)
            pDocumentary.setUrl(url);
            pDocumentary.setTrailerUrl(trailerUrl);
            pDocumentary.setCategory(selectedCategory);

            if (fileWasChanged) {
                const coverImageFile = await FileUploader.uploadFile(file, "documentary_cover_photo",
                    file["type"]);
                pDocumentary.setCoverPhotoFile(coverImageFile);
            }

            pDocumentary.setEnabled(true);
            await pDocumentary.save();

            setSuccess(true);
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (documentary === null) {
                cleanValues();
            }
        }
    }

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchDocumentary = async () => {
            try {
                setLoading(true);
                const documentary = await Documentary.getQuery().get(params.id)

                if (isMounted) {
                    setDocumentary(documentary);
                    setTitle(documentary.getTitle());
                    setDescription(documentary.getDescriptionString());
                    setUrl(documentary.getUrl());
                    setTrailerUrl(documentary.getTrailerUrl());
                    setSelectedCategory(documentary.getCategory());
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchDocumentary();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchDocumentariesCategory = async () => {
            try {
                const documentariesCategories = await DocumentariesCategory.getPickableQuery().find();
                if (isMounted) {
                    const mappedResults = documentariesCategories.map(function(item) { return {
                        key: item.id,
                        value: item.getTitle()
                    }});
                    setDocumentariesCategories(documentariesCategories);
                    setDocumentariesCategoriesKeyValue(mappedResults);
                    if (params.id === undefined) {
                        setSelectedCategory(documentariesCategories[0]);
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchDocumentariesCategory();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const confirmDelete = (documentary) => {
        if (documentary != null) {
            confirmAlert({
                title: 'Delete documentary',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteDocumentary(documentary)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const deleteDocumentary = async (documentary) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            documentary.setEnabled(false);

            await documentary.save();

            navigate("/documentaries");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (documentary === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setDocumentary(null);
        setTitle('');
        setDescription('');
        setUrl('');
        setTrailerUrl('');
        setCoverImageLocalFile(null);
    }

    const documentariesCategorySelectChangeHandler = (e) => {
        const documentariesCategory = documentariesCategories.filter(option => option.id === e.key)[0]
        setSelectedCategory(documentariesCategory);
    }

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];

        getBase64(file)
            .then(result => {
                file["base64"] = result;
                setCoverImageLocalFile(file);
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <div className="Documentary">
            <PageTitle title="Documentary"
                       brief="Add a documentary" >
                { documentary != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(documentary)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="documentary-cover-image"
                                                             label="Cover image">
                                            <FileInput name="documentary-cover-image"
                                                       title="Select a cover image"
                                                       accept={imageTypes}
                                                       previewImage={
                                                           coverImageLocalFile !== null ?
                                                               coverImageLocalFile["base64"] :
                                                               documentary !== null ?
                                                                   documentary.getCoverPhotoUrl() :
                                                                   null }
                                                       type={ecoItemCoverType}
                                                       onChange={handleFileInputChange}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="documentary-title"
                                                             label="Title">
                                            <TextInput id="documentary-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="documentary-description"
                                                             label="Description">
                                            <TextArea id="documentary-description"
                                                      value={description}
                                                      rows={8}
                                                      required={true}
                                                      autoComplete={false}
                                                      onChange={(e) => setDescription(e.target.value)} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="documentary-url"
                                                             label="URL">
                                            <TextInput id="documentary-url"
                                                       value={url}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setUrl(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="documentary-trailer-url"
                                                             label="Trailer URL">
                                            <TextInput id="documentary-trailer-url"
                                                       value={trailerUrl}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTrailerUrl(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                {documentariesCategoriesKeyValue && documentariesCategoriesKeyValue.length > 0 && selectedCategory != null &&
                                    <Row gutterWidth={20}>
                                        <Col>
                                            <LeftAlignLabelField id="documentary-category"
                                                                 label={"Category"}>
                                                <SelectInput name="documentary-category"
                                                             options={documentariesCategoriesKeyValue}
                                                             preSelectedOption={{
                                                                 key: selectedCategory.id,
                                                                 value: selectedCategory.getTitle()
                                                             }}
                                                             onChange={documentariesCategorySelectChangeHandler}/>
                                            </LeftAlignLabelField>
                                        </Col>
                                    </Row>
                                }
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The documentary was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditDocumentary;