import React from 'react';

import "./loading-container.scss"

const LoadingContainer = ({children}) => {
    return (
        <div className="LoadingContainer">
            {children}
        </div>
    );
};

export default LoadingContainer;

// <React.Fragment>
//     {/*{*/}
//     {/*    Array(10)*/}
//     {/*        .fill("")*/}
//     {/*        .map((e, i) => (*/}
//     {/*                <Loader key={i} style={{opacity: Number(2 / i).toFixed(1)}}/>*/}
//     {/*            )*/}
//     {/*        )*/}
//     {/*}*/}
// </React.Fragment>

// import ContentLoader from "react-content-loader";
//
// const Loader = props => {
//     const random = Math.random() * (1 - 0.7) + 0.7;
//     return (
//         <ContentLoader
//             height={32}
//             width={1718}
//             speed={2}
//             primaryColor="#d9d9d9"
//             secondaryColor="#ecebeb"
//             {...props}
//         >
//             {/*<rect x="30" y="15" rx="4" ry="4" width="6" height="6" />*/}
//             <rect x="8" y="9" rx="4" ry="4" width={200 * random} height="12" />
//             <rect x="900" y="9" rx="4" ry="4" width={40} height="12" />
//             {/*<rect x="683" y="9" rx="4" ry="4" width={78 * random} height="12" />*/}
//             {/*<rect x="785" y="9" rx="4" ry="4" width={117 * random} height="12" />*/}
//             {/*<rect x="968" y="9" rx="4" ry="4" width={83 * random} height="12" />*/}
//             <rect x="0" y="31.7" rx="4" ry="4" width="1718" height=".3" />
//         </ContentLoader>
//     );
// };