import React from 'react';
import {Oval} from "react-loader-spinner";

const LoadingOval = ({loading}) => {
    return (
        <div className="LoadingWrapper">
            <Oval
                height={32}
                width={32}
                color="#1c68ff"
                visible={loading}
                wrapperStyle={{}}
                ariaLabel='oval-loading'
                secondaryColor="#00a8ff"
                strokeWidth={3}
                strokeWidthSecondary={3}
            />
        </div>
    );
};

export default LoadingOval;