import React from 'react';

import "./form.scss"

const Form = (props) => {
    return (
        <div className="Form">
            {props.children}
        </div>
    );
};

export default Form;