import Parse from "parse";

class MusicPlaylist extends Parse.Object {

    constructor() {
        super('MusicPlaylist');
    }

    getTitle() {
        return this.get('title');
    }

    setTitle(title) {
        this.set('title', title)
    }


    setDescriptionString(description) {
        this.set('descriptionString', description);
    }

    getDescriptionString() {
        return this.get('descriptionString');
    }

    setUrl(url) {
        this.set('url', url);
    }

    getUrl() {
        return this.get('url');
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    static getQuery() {
        const parseQuery = new Parse.Query(MusicPlaylist);
        parseQuery.equalTo("enabled", true);
        parseQuery.ascending("title");
        return parseQuery;
    }
}

export default MusicPlaylist;