import React from 'react';

import "./hyperlink.scss"

const Hyperlink = ({href, text}) => {
    return (
        <a className="Hyperlink" href={href}>{text}</a>
    );
};

export default Hyperlink;