import React from 'react';

import "./button.scss"

const Button = ({title, type, icon, fill, classType, disabled, onClick}) => {
    return (
        <button className={'Button '
            + (classType !== undefined ? classType +'Button' : '')
            + ' '
            + ((fill !== undefined && fill === true) ? 'Button__fill' : '')}
                disabled={ disabled !== undefined ? disabled : false }
                onClick={onClick}
                type={type !== undefined ? type : "button" } >
            <div className={'Button__content '}>
                <span className={'Button__icon ' + (classType !== undefined ? classType +'Button__icon' : '')}>{ icon }</span>
                <span className={'Button__label ' + (classType !== undefined ? classType +'Button__label' : '')}>{ title }</span>
            </div>
        </button>
    );
};

export default Button;