import React from 'react';

import "./side-bar.scss"
import BusinessHeader from "./business-header/BusinessHeader";
import {
    FiHome, FiUsers, FiBook, FiBookOpen, FiFilm, FiShoppingCart, FiCalendar, FiVideo, FiMusic, FiVoicemail, FiMenu
} from "react-icons/fi";
import { NavLink } from "react-router-dom";

const SideBar = () => {

    // const { auth } = useAuth();

    return (
        <div className="SideBar">
            <BusinessHeader/>
            <div className="SideBar__wrapper">
                {/*{ auth?.roles.includes(1) &&*/}
                    <div>
                        <div className="SideBar__section">
                            <span className="SideBar__section-title">Posts</span>
                            <ul className="SideBar__section-items-list">
                                <NavLink to="/posts"
                                         className={({ isActive }) => (isActive ? " active" : "")} end>
                                    <li className="SideBar__section-item">
                                        <FiHome/> <span className="SideBar__section-item-title">Posts</span>
                                    </li>
                                </NavLink>
                            </ul>
                            <ul className="SideBar__section-items-list">
                                <NavLink to="/posts-categories"
                                         className={({ isActive }) => (isActive ? " active" : "")} >
                                    <li className="SideBar__section-item">
                                        <FiMenu/> <span className="SideBar__section-item-title">Post categories</span>
                                    </li>
                                </NavLink>
                            </ul>
                        </div>
                        <div className="SideBar__section">
                            <span className="SideBar__section-title">Videos</span>
                            <ul className="SideBar__section-items-list">
                                <NavLink to="/videos"
                                         className={({ isActive }) => (isActive ? " active" : "")} end>
                                    <li className="SideBar__section-item">
                                        <FiVideo/> <span className="SideBar__section-item-title">Videos</span>
                                    </li>
                                </NavLink>
                                <NavLink to="/videos-categories"
                                         className={({ isActive }) => (isActive ? " active" : "")} >
                                    <li className="SideBar__section-item">
                                        <FiMenu/> <span className="SideBar__section-item-title">Videos categories</span>
                                    </li>
                                </NavLink>
                            </ul>
                        </div>
                        <div className="SideBar__section">
                            <span className="SideBar__section-title">Podcasts</span>
                            <ul className="SideBar__section-items-list">
                                <NavLink to="/podcasts"
                                         className={({ isActive }) => (isActive ? " active" : "")} >
                                    <li className="SideBar__section-item">
                                        <FiVoicemail/> <span className="SideBar__section-item-title">Podcasts</span>
                                    </li>
                                </NavLink>
                            </ul>
                        </div>
                        <div className="SideBar__section">
                            <span className="SideBar__section-title">Shop</span>
                            <ul className="SideBar__section-items-list">
                                <NavLink to="/products"
                                         className={({ isActive }) => (isActive ? " active" : "")} >
                                    <li className="SideBar__section-item">
                                        <FiShoppingCart/> <span className="SideBar__section-item-title">Products</span>
                                    </li>
                                </NavLink>
                                <NavLink to="/shop-categories"
                                         className={({ isActive }) => (isActive ? " active" : "")} >
                                    <li className="SideBar__section-item">
                                        <FiMenu/> <span className="SideBar__section-item-title">Shop categories</span>
                                    </li>
                                </NavLink>
                                <NavLink to="/sale-events"
                                         className={({ isActive }) => (isActive ? " active" : "")} >
                                    <li className="SideBar__section-item">
                                        <FiCalendar/> <span className="SideBar__section-item-title">Sale events</span>
                                    </li>
                                </NavLink>
                            </ul>
                        </div>
                        <div className="SideBar__section">
                            <span className="SideBar__section-title">Eco</span>
                            <ul className="SideBar__section-items-list">
                                <NavLink to="/documentaries"
                                         className={({ isActive }) => (isActive ? " active" : "")} end>
                                    <li className="SideBar__section-item">
                                        <FiFilm/> <span className="SideBar__section-item-title">Documentaries</span>
                                    </li>
                                </NavLink>
                                <NavLink to="/documentaries-categories"
                                         className={({ isActive }) => (isActive ? " active" : "")} >
                                    <li className="SideBar__section-item">
                                        <FiMenu/> <span className="SideBar__section-item-title">Documentaries categories</span>
                                    </li>
                                </NavLink>
                                <NavLink to="/books"
                                         className={({ isActive }) => (isActive ? " active" : "")} end>
                                    <li className="SideBar__section-item">
                                        <FiBookOpen/> <span className="SideBar__section-item-title">Books</span>
                                    </li>
                                </NavLink>
                                <NavLink to="/books-categories"
                                         className={({ isActive }) => (isActive ? " active" : "")} >
                                    <li className="SideBar__section-item">
                                        <FiMenu/> <span className="SideBar__section-item-title">Book categories</span>
                                    </li>
                                </NavLink>
                                <NavLink to="/eco-dictionary"
                                         className={({ isActive }) => (isActive ? " active" : "")} >
                                    <li className="SideBar__section-item">
                                        <FiBook/> <span className="SideBar__section-item-title">Eco-dictionary</span>
                                    </li>
                                </NavLink>
                                <NavLink to="/music-playlists"
                                         className={({ isActive }) => (isActive ? " active" : "")} >
                                    <li className="SideBar__section-item">
                                        <FiMusic/> <span className="SideBar__section-item-title">Playlists</span>
                                    </li>
                                </NavLink>
                            </ul>
                        </div>
                    </div>
                {/*/!*}*!/*/}
                {/*/!*{ auth?.roles.includes(2) &&*!/*/}
                {/*    <div>*/}
                {/*        <div className="SideBar__section">*/}
                {/*            <span className="SideBar__section-title">Admin</span>*/}
                {/*            <ul className="SideBar__section-items-list">*/}
                {/*                <NavLink to="/admin/users/"*/}
                {/*                         className={({ isActive }) => (isActive ? " active" : "")} >*/}
                {/*                    <li className="SideBar__section-item">*/}
                {/*                        <FiUsers/> <span className="SideBar__section-item-title">Users</span>*/}
                {/*                    </li>*/}
                {/*                </NavLink>*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*/!*}*!/*/}
            </div>
        </div>
    );
};

export default SideBar;