import React from 'react';

import "./notice.scss"
import {FiInfo} from "react-icons/fi";

const Notice = ({icon, type, children, fullWidth}) => {
    return (
        <div className="Notice__wrapper">
            <div className={"Notice Notice-" + type + (fullWidth !== undefined && fullWidth === true ? " Notice-full-width" : "") }>
                <span className={"Notice__icon Notice__icon-" + type }>{ (icon !== undefined ? icon : <FiInfo/>) }</span>
                <span className={"Notice__text Notice__text-" + type }> { children } </span>
            </div>
        </div>
    );
};

export default Notice;