import React, {useEffect, useState} from "react";
import PageTitle from "../../../components/common/page-title/PageTitle";
import {Link} from "react-router-dom";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import {FiPlus} from "react-icons/fi";
import CategoriesTable from "../../../components/tables/CategoriesTable";
import ShopCategory from "../../../classes/ShopCategory";

const ShopCategories = () => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchShopCategories = async () => {
            try {
                const results = await ShopCategory.getQuery().find();
                if (isMounted) {
                    setItems(results);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchShopCategories();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    return (
        <div>
            <PageTitle title="Shop categories">
                <Link to="./new">
                    <PrimaryButton title="New shop category" icon={<FiPlus/>}/>
                </Link>
            </PageTitle>
            <CategoriesTable items={items} loading={loading} />
        </div>
    );
};

export default ShopCategories;