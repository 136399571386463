import Parse from "parse";
import {Languages} from "../utilities/Constants";

class DictionaryEntry extends Parse.Object {
    constructor() {
        super('DictionaryEntry');
    }

    getTerm() {
        return this.get('term');
    }

    setTerm(term) {
        this.set('term', term)
    }

    getMeaning() {
        return this.get('meaning');
    }

    setMeaning(meaning) {
        this.set('meaning', meaning);
    }

    getLanguage() {
        const languageCode = this.get('languageCodeString');
        return Languages.filter(option => option.key === languageCode)[0]
    }

    setLanguageCode(code) {
        this.set('languageCodeString', code);
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    static getQuery() {
        const parseQuery = new Parse.Query(DictionaryEntry);
        parseQuery.equalTo("enabled", true);
        parseQuery.ascending("term");
        return parseQuery;
    }
}

export default DictionaryEntry;