import React from 'react';

import "./left-align-label-field.scss"

const LeftAlignLabelField = ({children, id, label, brief, button}) => {
    return (
        <div className="LeftAlignLabelField" >
            <label id={id} className="LeftAlignLabelField__label">
                {label}
            </label>
            <div className="LeftAlignLabelField__fields">
                <div className="LeftAlignLabelField__input-field">
                    {children}
                </div>
                { brief !== undefined &&
                    <p className="LeftAlignLabelField__input-brief">
                        {brief}
                    </p>
                }
                { button !== undefined &&
                    <div className="LeftAlignLabelField__input-button">
                        {button}
                    </div>
                }
            </div>
        </div>
    );
};

export default LeftAlignLabelField;