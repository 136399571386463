import React from 'react';

import "./buttons-horizontal-bar.scss"

const ButtonsHorizontalBar = ({children, addPadding = false}) => {
    return (
        <div className={"ButtonsHorizontalBar " + (addPadding ? "ButtonsHorizontalBar__padding" : "")}>
            {children}
        </div>
    );
};

export default ButtonsHorizontalBar;