import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import FileUploader from "../../../../helpers/FileUploader";
import FileInput, {
    ecoCategoryCoverType,
    getBase64,
    imageTypes,
} from "../../../../components/common/inputs/file-input/FileInput";
import PageTitle from "../../../../components/common/page-title/PageTitle";
import RedButton from "../../../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../../../components/common/loading/LoadingOval";
import InsideContentForm from "../../../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../../../components/common/inputs/text-input/TextInput";
import Notice from "../../../../components/common/notice/Notice";
import DividerFooter from "../../../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../../../components/common/footer/InsideContentFormFooter";
import Button from "../../../../components/common/buttons/Button";
import PrimaryButton from "../../../../components/common/buttons/PrimaryButton";
import BooksCategory from "../../../../classes/BooksCategory";

const EditBooksCategory = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [booksCategory, setBooksCategory] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [coverImageLocalFile, setCoverImageLocalFile] = useState(null);
    const [title, setTitle] = useState('');
    const [spanishTitle, setSpanishTitle] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        saveBooksCategory(booksCategory, title, spanishTitle, coverImageLocalFile, (coverImageLocalFile != null));
    };

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchBooksCategory = async () => {
            try {
                setLoading(true);
                const booksCategory = await BooksCategory.getPickableQuery().get(params.id)

                if (isMounted) {
                    setBooksCategory(booksCategory);
                    setTitle(booksCategory.getTitle());
                    setSpanishTitle(booksCategory.getSpanishTitle());
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchBooksCategory();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const confirmDelete = (booksCategory) => {
        if (booksCategory != null) {
            confirmAlert({
                title: 'Delete books category',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteBooksCategory(booksCategory)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const deleteBooksCategory = async (booksCategory) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            booksCategory.setEnabled(false);

            await booksCategory.save();

            navigate("/books-categories");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (booksCategory === null) {
                cleanValues();
            }
        }
    }

    const saveBooksCategory = async (booksCategory,
                                             title,
                                             spanishTitle,
                                             file,
                                             fileWasChanged) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            let pBooksCategory = booksCategory

            if (booksCategory == null) {
                pBooksCategory = new BooksCategory();
            }

            pBooksCategory.setTitle(title);
            pBooksCategory.setSpanishTitle(spanishTitle);

            if (fileWasChanged) {
                const coverImageFile = await FileUploader.uploadFile(file, "books_category_cover_photo",
                    file["type"]);
                pBooksCategory.setCoverPhotoFile(coverImageFile);
            }

            pBooksCategory.setPickable(true);
            pBooksCategory.setEditable(true);
            pBooksCategory.setEnabled(true);
            await pBooksCategory.save();

            setSuccess(true);
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (booksCategory === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setBooksCategory(null);
        setTitle('');
        setSpanishTitle('');
        setCoverImageLocalFile(null);
    }

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];

        getBase64(file)
            .then(result => {
                file["base64"] = result;
                setCoverImageLocalFile(file);
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <div className="BooksCategory">
            <PageTitle title="Books category"
                       brief="Add a books category" >
                { booksCategory != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(booksCategory)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="books-category-cover-image"
                                                             label="Cover image">
                                            <FileInput name="books-category-cover-image"
                                                       title="Select a cover image"
                                                       accept={imageTypes}
                                                       previewImage={
                                                           coverImageLocalFile !== null ?
                                                               coverImageLocalFile["base64"] :
                                                               booksCategory !== null ?
                                                                   booksCategory.getCoverPhotoUrl() :
                                                                   null }
                                                       type={ecoCategoryCoverType}
                                                       onChange={handleFileInputChange}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="books-category-title"
                                                             label="Title">
                                            <TextInput id="books-category-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="books-category-spanish-title"
                                                             label="Spanish title">
                                            <TextInput id="books-category-spanish-title"
                                                       value={spanishTitle}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setSpanishTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The books category was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditBooksCategory;