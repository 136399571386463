import Parse from "parse";

class Book extends Parse.Object {

    constructor() {
        super('Book');
    }

    getTitle() {
        return this.get('title');
    }

    setTitle(title) {
        this.set('title', title)
    }

    getDescriptionString() {
        return this.get('descriptionString');
    }

    setDescriptionString(descriptionString) {
        this.set('descriptionString', descriptionString);
    }

    getUrl() {
        return this.get('url');
    }

    setUrl(url) {
        this.set('url', url)
    }

    getCategory() {
        return this.get("category");
    }

    getCategoryTitle() {
        return this.get("category").getTitle();
    }

    setCategory(category) {
        this.set('category', category);
        this.set('categoryId', category.id);
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    getCoverPhotoUrl() {
        return this.get("coverPhoto").url();
    }

    setCoverPhotoFile(file) {
        this.set("coverPhoto", file);
    }

    static getQuery() {
        const parseQuery = new Parse.Query(Book);
        parseQuery.equalTo("enabled", true);
        parseQuery.include("category");
        return parseQuery;
    }

    static getFilteredQuery(categoryId, skip, limit) {
        const parseQuery = new Parse.Query(Book);
        parseQuery.equalTo("enabled", true);
        parseQuery.include("category");
        parseQuery.skip(skip);
        parseQuery.limit(limit);
        parseQuery.ascending("title")
        parseQuery.withCount()
        if (categoryId != null) {
            parseQuery.equalTo("categoryId", categoryId);
        }
        return parseQuery;
    }
}

export default Book;