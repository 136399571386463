import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Languages} from "../../../utilities/Constants";
import Product from "../../../classes/Product";
import ShopCategory from "../../../classes/ShopCategory";
import {confirmAlert} from "react-confirm-alert";
import {
    getBase64,
} from "../../../components/common/inputs/file-input/FileInput";
import PageTitle from "../../../components/common/page-title/PageTitle";
import RedButton from "../../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../../components/common/loading/LoadingOval";
import InsideContentForm from "../../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../../components/common/inputs/text-input/TextInput";
import TextArea from "../../../components/common/inputs/text-area/TextArea";
import SelectInput from "../../../components/common/inputs/select-input/SelectInput";
import Notice from "../../../components/common/notice/Notice";
import DividerFooter from "../../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../../components/common/footer/InsideContentFormFooter";
import Button from "../../../components/common/buttons/Button";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import MultipleImagesInput from "../../../components/common/inputs/multiple-images-input/MultipleImagesInput";
import ProductImage from "../../../classes/ProductImage";
import FileUploader from "../../../helpers/FileUploader";

class LocalProductImage {

    source;
    remoteProductImage;
    localFile;

    constructor(source, remoteProductImage, localFile) {
        this.source = source;
        this.remoteProductImage = remoteProductImage;
        this.localFile = localFile;
    }

}

const EditProduct = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [product, setProduct] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [imagesLoading, setImagesLoading] = useState(true);

    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [productImageLocalFiles, setProductImageLocalFiles] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [priceInUSD, setPriceInUSD] = useState(0);
    const [affiliateLink, setAffiliateLink] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState(Languages[0]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [shopCategories, setShopCategories] = useState([]);
    const [shopCategoriesKeyValue, setShopCategoriesKeyValue] = useState([]);

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchProduct = async () => {
            try {
                setLoading(true);
                const product = await Product.getQuery().get(params.id)

                if (isMounted) {
                    setProduct(product);
                    setTitle(product.getTitle());
                    setDescription(product.getDescription());
                    setPriceInUSD(product.getPriceInUSD());
                    setAffiliateLink(product.getAffiliateLink());
                    setSelectedCategory(product.getCategory());
                    setSelectedLanguage(product.getLanguage());
                    setImagesToDelete([]);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchProduct();

        const fetchProductImages = async () => {
            try {
                setImagesLoading(true);
                const productImages = await ProductImage.getQuery(params.id).find();

                if (isMounted) {
                    setProductImageLocalFiles(
                        productImages.map(item => new LocalProductImage(item.getImageUrl(), item, null)))
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setImagesLoading(false);
            }
        }

        fetchProductImages();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchShopCategories = async () => {
            try {
                const shopCategories = await ShopCategory.getQuery().find();
                if (isMounted) {
                    const mappedResults = shopCategories.map(function(item) { return {
                        key: item.id,
                        value: item.getTitle()
                    }});
                    setShopCategories(shopCategories);
                    setShopCategoriesKeyValue(mappedResults);
                    if (params.id === undefined) {
                        setSelectedCategory(shopCategories[0]);
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchShopCategories();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, [])

    const languageSelectChangeHandler = (e) => {
        setSelectedLanguage(e);
    }

    const shopCategorySelectChangeHandler = (e) => {
        const shopCategory = shopCategories.filter(option => option.id === e.key)[0]
        setSelectedCategory(shopCategory);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        saveProduct(product,
            title,
            description,
            priceInUSD,
            affiliateLink,
            selectedCategory,
            selectedLanguage.key,
            imagesToDelete,
            productImageLocalFiles);
    };

    const confirmDelete = (product) => {
        if (product != null) {
            confirmAlert({
                title: 'Delete product',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteProduct(product)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const saveProduct = async (product,
                               title,
                               description,
                               priceInUSD,
                               affiliateLink,
                               category,
                               languageCode,
                               imagesToDelete,
                               imageFiles) => {

        try {

            setSaving(true);
            setError(null);
            setSuccess(false);

            let pProduct = product;

            if (product === null) {
                pProduct = new Product();
            }

            pProduct.setTitle(title);
            pProduct.setDescription(description);
            pProduct.setPriceInUSD(priceInUSD);
            pProduct.setAffiliateLink(affiliateLink);
            pProduct.setCategory(category);
            pProduct.setLanguageCode(languageCode);

            pProduct = await pProduct.save();
            await deleteImages(imagesToDelete);
            await saveImages(imageFiles, pProduct);
            const uploadedProductImages = imageFiles.filter(image => image.remoteProductImage != null);
            if (uploadedProductImages.length > 0) {
                pProduct.setMainImage(uploadedProductImages[0].remoteProductImage);
            }
            pProduct.setEnabled(true);
            pProduct = await pProduct.save();

            setSuccess(true);
            if (product === null) {
                cleanValues();
            }
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
        }
    }

    const deleteImages = async (imagesToDelete) => {
        const promises = [];

        if (imagesToDelete != null) {
            for (const imageToDelete of imagesToDelete) {
                imageToDelete.setEnabled(false);
                promises.push(imageToDelete.save());
            }
        }
        return Promise.all(promises);
    }

    const saveImages = async (images, product) => {

        const promises = [];

        for (const [index, image] of images.entries()) {
            if (image.remoteProductImage == null) {
                const productImage = new ProductImage();
                const file = await FileUploader.uploadFile(
                    image.localFile, product.id + "-image-" + index, image.localFile["type"]);
                productImage.setImage(file);
                productImage.setProduct(product);
                productImage.setEnabled(true);
                promises.push(productImage.save().then(function (savedProductImage) {
                    image.remoteProductImage = savedProductImage;
                }));
            }
            else {
                promises.push()
            }
        }
        return Promise.all(promises);
    }

    const deleteProduct = async (product) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            product.setEnabled(false);

            await product.save();

            navigate("/products");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (product === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setProduct(null);
        setImagesToDelete([]);
        setProductImageLocalFiles([]);
        setTitle('');
        setDescription('');
        setPriceInUSD(0);
        setAffiliateLink('');
        setSelectedLanguage(Languages[0]);
        setSelectedCategory(shopCategories > 0 ? shopCategories[0] : null)
    }

    const handleFileInputChange = async (e) => {

        if (e.target.files.length === 0) {
            return;
        }

        if (e.target.files.length > (4 - productImageLocalFiles.length)) {

            alert("You can select up to " + (4 - productImageLocalFiles.length) + " images maximum. Try again or delete some images from the product.");
            return;
        }

        const tempLocalFiles = productImageLocalFiles.slice();

        for (let i = 0; i < e.target.files.length; i++) {
            if (tempLocalFiles.length < 4) {
                const file = e.target.files[i];
                try {
                    const result = await getBase64(file);
                    file["base64"] = result;
                    tempLocalFiles.push(new LocalProductImage(result, null, file));
                }
                catch (error) {
                    console.log(error);
                }
            }
        }
        setProductImageLocalFiles(tempLocalFiles);
    };

    const handleFileInputDelete = (index) => {
        const toDelete = productImageLocalFiles.splice(index, 1);
        if (toDelete[0].remoteProductImage != null) {
            const remoteProductImage = toDelete[0].remoteProductImage;
            const tempImagesToDelete = imagesToDelete.slice();
            tempImagesToDelete.push(remoteProductImage)
            setImagesToDelete(tempImagesToDelete);
        }
        setProductImageLocalFiles(productImageLocalFiles.slice());
    };

    return (
        <div className="EditProduct">
            <PageTitle title="Product"
                       brief="Add a product" >
                { product != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving}
                                                onClick={() => confirmDelete(product)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="product-image"
                                                             label="Cover image">
                                            <MultipleImagesInput name={"product-image"}
                                                                 title={"Select a product image"}
                                                                 previewImages={productImageLocalFiles}
                                                                 onChange={handleFileInputChange}
                                                                 onDelete={handleFileInputDelete} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="product-title"
                                                             label="Title">
                                            <TextInput id="product-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="product-description"
                                                             label="Description">
                                            <TextArea id="product-description"
                                                      value={description}
                                                      rows={8}
                                                      required={true}
                                                      autoComplete={false}
                                                      onChange={(e) => setDescription(e.target.value)} />
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="product-price-in-usd"
                                                             label="Price (USD)">
                                            <TextInput id="product-price-in-usd"
                                                       value={priceInUSD}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setPriceInUSD(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="product-affiliate-link"
                                                             label="Affiliate link">
                                            <TextInput id="product-price-in-usd"
                                                       value={affiliateLink}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setAffiliateLink(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                {shopCategoriesKeyValue && shopCategoriesKeyValue.length > 0 && selectedCategory != null &&
                                    <Row gutterWidth={20}>
                                        <Col>
                                            <LeftAlignLabelField id="product-shop-category"
                                                                 label={"Category"}>
                                                <SelectInput name="product-shop-category"
                                                             options={shopCategoriesKeyValue}
                                                             preSelectedOption={{
                                                                 key: selectedCategory.id,
                                                                 value: selectedCategory.getTitle()
                                                             }}
                                                             onChange={shopCategorySelectChangeHandler}/>
                                            </LeftAlignLabelField>
                                        </Col>
                                    </Row>
                                }
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="product-language-code"
                                                             label={"Language"}>
                                            <SelectInput name="product-language-code"
                                                         options={Languages}
                                                         preSelectedOption={selectedLanguage}
                                                         onChange={languageSelectChangeHandler}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The product was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditProduct;