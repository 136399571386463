import React from 'react';
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../inputs/text-input/TextInput";
import FileInput, {
    emptyPostSectionImageType,
    getBase64,
    imageTypes,
    postSectionImageType
} from "../inputs/file-input/FileInput";
import TextArea from "../inputs/text-area/TextArea";
import TextButton from "../buttons/TextButton";
import DashedLineSeparator from "../separators/DashedLineSeparator";
import FieldsTitle from "../fields-title/FieldsTitle";

const PostSectionForm = ({localPostSection, index, onChange}) => {

    const subtitleChangeHandler = (e) => {
        localPostSection.setSubtitle(e.target.value);
        onChange(localPostSection, index);
    }

    const bodyChangeHandler = (e) => {
        localPostSection.setBody(e.target.value);
        onChange(localPostSection, index);
    }

    const removeSectionHandler = () => {
        localPostSection.setDeleted(true);
        onChange(localPostSection, index);
    }

    const swapSubtitleStatusHandler = () => {
        if (localPostSection.getSubtitle() != null) {
            localPostSection.setSubtitle(null);
        }
        else {
            localPostSection.setSubtitle("");
        }
        onChange(localPostSection, index);
    }

    const handlePhotoFileInputChange = async (e) => {
        const file = e.target.files[0];
        try {
            file["base64"] = await getBase64(file);
            localPostSection.setLocalImage(file);
            onChange(localPostSection);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handlePhotoFileInputDelete = (e) => {
        localPostSection.setLocalImage(null);
        localPostSection.setImageUrl(null);
        onChange(localPostSection, index);
    }

    return (
        <div>
            <FieldsTitle title={"Section #" + (index + 1)} >
                <TextButton text="Delete" type="Destructive" onClick={removeSectionHandler} />
            </FieldsTitle>
            <Container fluid>
                <Row gutterWidth={20}>
                    <Col>
                        <LeftAlignLabelField id="post-section-image"
                                             label="Photo"
                                             brief="Optional">
                            <FileInput name="post-section-image"
                                       title="Select an image"
                                       accept={imageTypes}
                                       previewImage={
                                           localPostSection.getLocalImage() != null ?
                                               localPostSection.getLocalImage()["base64"] :
                                               localPostSection.getImageUrl() != null ?
                                                   localPostSection.getImageUrl() :
                                                   null }
                                       type={ (localPostSection.getLocalImage() != null || localPostSection.getImageUrl() != null)
                                           ? postSectionImageType
                                           : emptyPostSectionImageType}
                                       onChange={handlePhotoFileInputChange}
                                       onDelete={handlePhotoFileInputDelete} />
                        </LeftAlignLabelField>
                    </Col>
                </Row>
                <Row gutterWidth={20}>
                    <Col>
                        <LeftAlignLabelField id="post-section-subtitle"
                                             label="Subtitle"
                                             button= {
                                                 localPostSection.getSubtitle() != null ?
                                                     <TextButton text="Remove subtitle" onClick={swapSubtitleStatusHandler} />
                                                     :
                                                     <TextButton text="Add subtitle" onClick={swapSubtitleStatusHandler} />
                                             }
                                             brief="Optional">
                            { localPostSection.getSubtitle() != null &&
                                <TextInput id="post-section-subtitle"
                                           value={localPostSection.getSubtitle()}
                                           required={true}
                                           autoComplete={false}
                                           onChange={subtitleChangeHandler}/>
                            }
                        </LeftAlignLabelField>
                    </Col>
                </Row>
                <Row gutterWidth={20}>
                    <Col>
                        <LeftAlignLabelField id="post-section-body"
                                             label="Section text">
                            <TextArea id="post-section-body"
                                      value={localPostSection.getBody()}
                                      rows={8}
                                      required={true}
                                      autoComplete={false}
                                      onChange={bodyChangeHandler} />
                        </LeftAlignLabelField>
                    </Col>
                </Row>
                <DashedLineSeparator/>
            </Container>
        </div>
    );
};

export default PostSectionForm;