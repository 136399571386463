import Parse from "parse";
import {Languages} from "../utilities/Constants";

class Product extends Parse.Object {

    constructor() {
        super('Product');
    }

    getTitle() {
        return this.get('title');
    }

    setTitle(title) {
        this.set('title', title)
    }

    getDescription() {
        return this.get('descriptionString');
    }

    setDescription(description) {
        this.set('descriptionString', description)
    }

    getPriceInUSD() {
        return this.get('priceInUSD');
    }

    setPriceInUSD(price) {
        this.set('priceInUSD', price)
    }

    getAffiliateLink() {
        return this.get('affiliateLink');
    }

    setAffiliateLink(affiliateLink) {
        this.set('affiliateLink', affiliateLink);
    }

    getMainImageUrl() {
        return this.get("mainImage").get("image").url();
    }

    setMainImage(mainImage) {
        this.set('mainImage', mainImage);
        this.set('mainImageId', mainImage.id);
    }

    getCategory() {
        return this.get("category");
    }

    getCategoryTitle() {
        return this.get("category").getTitle();
    }

    setCategory(category) {
        this.set('category', category);
        this.set('categoryId', category.id);
    }

    getLanguage() {
        const languageCode = this.get('languageCodeString');
        return Languages.filter(option => option.key === languageCode)[0]
    }

    setLanguageCode(code) {
        this.set('languageCodeString', code);
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    static getQuery() {
        const parseQuery = new Parse.Query(Product);
        parseQuery.equalTo("enabled", true);
        parseQuery.include("category");
        parseQuery.include("mainImage");
        return parseQuery;
    }

    static getFilteredQuery(languageCode, categoryId, skip, limit) {
        const parseQuery = new Parse.Query(Product);
        parseQuery.equalTo("enabled", true);
        parseQuery.include("category");
        parseQuery.include("mainImage");
        parseQuery.skip(skip);
        parseQuery.limit(limit);
        parseQuery.ascending("title")
        parseQuery.withCount()
        if (languageCode != null) {
            parseQuery.equalTo("languageCodeString", languageCode);
        }
        if (categoryId != null) {
            parseQuery.equalTo("categoryId", categoryId);
        }
        return parseQuery;
    }
}

export default Product;