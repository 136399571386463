import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Languages} from "../../utilities/Constants";
import Post from "../../classes/Post";
import Category from "../../classes/Category";
import {confirmAlert} from "react-confirm-alert";
import FileInput, {
    getBase64,
    imageTypes,
    postCoverType
} from "../../components/common/inputs/file-input/FileInput";
import PageTitle from "../../components/common/page-title/PageTitle";
import RedButton from "../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../components/common/loading/LoadingOval";
import InsideContentForm from "../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../components/common/inputs/text-input/TextInput";
import SelectInput from "../../components/common/inputs/select-input/SelectInput";
import Notice from "../../components/common/notice/Notice";
import DividerFooter from "../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../components/common/footer/InsideContentFormFooter";
import Button from "../../components/common/buttons/Button";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import PostSection from "../../classes/PostSection";
import SectionTitle from "../../components/common/section-title/SectionTitle";
import InsideContentFormPaddingContainer from "../../components/common/form/InsideContentFormPaddingContainer";
import LocalPostSection from "../../classes/LocalPostSection";
import PostSectionForm from "../../components/common/form/PostSectionForm";
import ButtonsHorizontalBar from "../../components/common/bars/ButtonsHorizontalBar";
import FileUploader from "../../helpers/FileUploader";

const EditPost = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [post, setPost] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [coverImageLocalFile, setCoverImageLocalFile] = useState(null);

    const [title, setTitle] = useState('');
    const [tagline, setTagline] = useState('');
    const [youtubeVideoUrl, setYoutubeVideoUrl] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState(Languages[0]);
    const [postsCategories, setPostsCategories] = useState([]);
    const [postsCategoriesKeyValue, setPostsCategoriesKeyValue] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [postSections, setPostSections] = useState([]);
    const [localPostSections, setLocalPostSections] = useState([new LocalPostSection()]);
    const [deletedPostSections, setDeletedPostSections] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        savePost(post,
            title,
            tagline,
            youtubeVideoUrl,
            selectedLanguage.key,
            selectedCategory,
            coverImageLocalFile,
            localPostSections,
            deletedPostSections)
    };

    const deletePostsSections = async(deletedPostSections) => {
        const promises = [];

        for (let postSection of deletedPostSections) {
            postSection.setEnabled(false);
            promises.push(postSection.save());
        }
        return Promise.all(promises);
    }

    const savePost = async (post,
                            title,
                            tagline,
                            youTubeVideoUrl,
                            language,
                            selectedCategory,
                            coverPhotoFile,
                            localPostSections,
                            deletedPostSections) => {

        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            await deletePostsSections(deletedPostSections);

            let pPost = post;

            if (post == null) {
                pPost = new Post();
            }

            pPost.setTitle(title);
            pPost.setTagline(tagline);
            pPost.setVideoURL(youtubeVideoUrl);
            pPost.setCategory(selectedCategory);
            pPost.setLanguageCode(language);

            if (coverPhotoFile != null) {
                const coverImageFile = await FileUploader.uploadFile(coverPhotoFile,
                    "post_cover_photo",
                    coverPhotoFile["type"]);
                pPost.setCoverPhotoFile(coverImageFile);
            }

            pPost = await pPost.save();

            await savePostSections(localPostSections, pPost);

            pPost.setEnabled(true);
            await pPost.save();

            setSuccess(true);
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (post === null) {
                cleanValues();
            }
        }
    }

    const savePostSections = async (localPostSections, post) => {
        const promises = [];
        for (const [index, localPostSection] of localPostSections.entries()) {
            promises.push(savePostSection(localPostSection, post, index));
        }
        return Promise.all(promises);
    }

    const savePostSection = async (localPostSection, post, order) => {
        let postSection  = localPostSection.getPostSection();
        if (postSection == null) {
            postSection = new PostSection();
        }
        postSection.setSubtitle(localPostSection.getSubtitle());
        postSection.setBody(localPostSection.getBody());
        postSection.setPost(post);
        postSection.setOrder(order);

        if (localPostSection.getLocalImage() != null) {
            const localImage = localPostSection.getLocalImage()
            const postSectionPhoto = await FileUploader.uploadFile(localImage,
                "post_section_" + order + "_cover_photo",
                localImage["type"]);
            postSection.setPhotoFile(postSectionPhoto);
        }
        else if (localPostSection.getImageUrl() == null) {
            postSection.setPhotoFile(null);
        }

        postSection.setEnabled(true);
        return postSection.save();
    }

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchPost = async () => {
            try {
                setLoading(true);
                const post = await Post.getQuery().get(params.id)

                if (isMounted) {
                    setPost(post);
                    setTitle(post.getTitle());
                    setTagline(post.getTagline());
                    setYoutubeVideoUrl(post.getVideoURL());
                    setSelectedLanguage(post.getLanguage());
                    setSelectedCategory(post.getCategory());
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchPost();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchPostSections = async () => {
            try {
                const postSections = await PostSection.getQuery(params.id).find();

                if (isMounted) {
                    setPostSections(postSections);
                    const localPostSections = postSections.map(function (postSection) {
                        const localPostSection = new LocalPostSection();
                        localPostSection.setPostSection(postSection);
                        localPostSection.setSubtitle(postSection.getSubtitle());
                        localPostSection.setImageUrl(postSection.getPhotoUrl());
                        localPostSection.setBody(postSection.getBody());
                        return localPostSection;
                    });
                    setLocalPostSections(localPostSections);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchPostSections();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchPostsCategories = async () => {
            try {
                const postsCategories = await Category.getPickableQuery().find();
                if (isMounted) {
                    const mappedResults = postsCategories.map(function(item) { return {
                        key: item.id,
                        value: item.getTitle()
                    }});
                    setPostsCategories(postsCategories);
                    setPostsCategoriesKeyValue(mappedResults);
                    if (params.id === undefined) {
                        setSelectedCategory(postsCategories[0]);
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchPostsCategories();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const confirmDelete = (post) => {
        if (post != null) {
            confirmAlert({
                title: 'Delete post',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deletePost(post)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const deletePost = async (post) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            post.setEnabled(false);

            await post.save();

            navigate("/posts");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (post === null) {
                cleanValues();
            }
        }
    }

    const languageSelectChangeHandler = (e) => {
        setSelectedLanguage(e);
    }

    const postCategorySelectChangeHandler = (e) => {
        const postCategory = postsCategories.filter(option => option.id === e.key)[0]
        setSelectedCategory(postCategory);
    }

    const postSectionChangeHandler = (postSection, index) => {

        if (postSection.isDeleted()) {

            let tempArray = [...localPostSections];

            let tempDeletedArray = [...deletedPostSections];
            let deletedLocalPostSection = tempArray.splice(index, 1)[0];
            tempDeletedArray.push(deletedLocalPostSection.getPostSection());

            if (tempArray.length === 0) {
                tempArray.push(new LocalPostSection());
            }

            setDeletedPostSections(tempDeletedArray);
            setLocalPostSections(tempArray);
        }
        else {
            let tempArray = [...localPostSections]
            tempArray[index] = postSection;
            setLocalPostSections(tempArray);
        }
    }

    const handleCoverImageFileInputChange = async (e) => {
        const file = e.target.files[0];
        try {
            file["base64"] = await getBase64(file);
            setCoverImageLocalFile(file);
        }
        catch (error) {
            console.log(error);
        }
    };

    const addPostSectionHandler = (e) => {
        if (localPostSections.length < 5) {
            let tempArray = [...localPostSections]
            tempArray.push(new LocalPostSection());
            setLocalPostSections(tempArray);
        }
    }

    function cleanValues() {
        setPost(null);
        setTitle('');
        setTagline('');
        setYoutubeVideoUrl('');
        setCoverImageLocalFile(null);
        setSelectedLanguage(Languages[0]);
        setPostSections([]);
        setLocalPostSections([new LocalPostSection()])
        setDeletedPostSections([]);
    }

    return (
        <div className="EditPost">
            <PageTitle title="Post"
                       brief="Add a post" >
                { post != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(post)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="post-cover-image"
                                                             label="Cover image">
                                            <FileInput name="post-cover-image"
                                                       title="Select a cover image"
                                                       accept={imageTypes}
                                                       previewImage={
                                                           coverImageLocalFile !== null ?
                                                               coverImageLocalFile["base64"] :
                                                               post !== null ?
                                                                   post.getImageUrl() :
                                                                   null }
                                                       type={postCoverType}
                                                       onChange={handleCoverImageFileInputChange}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="post-title"
                                                             label="Title">
                                            <TextInput id="post-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="post-tagline"
                                                             label="Tagline">
                                            <TextInput id="post-tagline"
                                                       value={tagline}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTagline(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="youtube-video-url"
                                                             label="YouTube video URL"
                                                             brief="Optional">
                                            <TextInput id="youtube-video-url"
                                                       value={youtubeVideoUrl}
                                                       required={false}
                                                       autoComplete={false}
                                                       onChange={(e) => setYoutubeVideoUrl(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                {postsCategoriesKeyValue && postsCategoriesKeyValue.length > 0 && selectedCategory != null &&
                                    <Row gutterWidth={20}>
                                        <Col>
                                            <LeftAlignLabelField id="post-category"
                                                                 label={"Category"}>
                                                <SelectInput name="post-category"
                                                             options={postsCategoriesKeyValue}
                                                             preSelectedOption={{
                                                                 key: selectedCategory.id,
                                                                 value: selectedCategory.getTitle()
                                                             }}
                                                             onChange={postCategorySelectChangeHandler}/>
                                            </LeftAlignLabelField>
                                        </Col>
                                    </Row>
                                }
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="post-language-code"
                                                             label={"Language"}>
                                            <SelectInput name="post-language-code"
                                                         options={Languages}
                                                         preSelectedOption={selectedLanguage}
                                                         onChange={languageSelectChangeHandler}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <InsideContentFormPaddingContainer>
                                    <SectionTitle title="Content" brief="Use sections to add organized content. You can add up to 5 sections." />
                                </InsideContentFormPaddingContainer>
                                <div>
                                    { localPostSections.map((item, index) => {
                                        return <PostSectionForm localPostSection={item} key={index} index={index} onChange={postSectionChangeHandler} />
                                    })}
                                </div>
                                <ButtonsHorizontalBar addPadding={true}>
                                    <PrimaryButton title="Add section" type="button" onClick={addPostSectionHandler} disabled={localPostSections.length >= 5} />
                                </ButtonsHorizontalBar>
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The post was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditPost;