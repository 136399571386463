import Parse from "parse";

class CustomUser extends Parse.User {

    constructor(attributes) {
        super(attributes);
    }

    getFullName() {
        return this.get("fullName");
    }

    getFirstName() {
        return this.get("firstName");
    }

    getLastName() {
        return this.get("lastName");
    }
}

export default CustomUser;