import React from 'react';

import "./inside-content-form-footer.scss"

const InsideContentFormFooter = ({children, addBottomMargin}) => {
    return (
        <div className={'InsideContentFormFooter ' + ((addBottomMargin !== undefined && addBottomMargin === true) ? 'Content-bottom-margin' : '') } >
            <div className="InsideContentFormFooter__container">
                {children}
            </div>
        </div>
    );
};

export default InsideContentFormFooter;