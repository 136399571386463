import {createContext, useEffect, useState} from "react";
import Parse from "parse";
import DictionaryEntry from "../classes/DictionaryEntry";
import ShopCategory from "../classes/ShopCategory";
import SaleEvent from "../classes/SaleEvent";
import Product from "../classes/Product";
import ProductImage from "../classes/ProductImage";
import DocumentariesCategory from "../classes/DocumentariesCategory";
import Documentary from "../classes/Documentary";
import BooksCategory from "../classes/BooksCategory";
import Book from "../classes/Book";
import MusicPlaylist from "../classes/MusicPlaylist";
import Video from "../classes/Video";
import VideoCategory from "../classes/VideoCategory";
import Category from "../classes/Category";
import Podcast from "../classes/Podcast";
import CustomUser from "../classes/CustomUser";
import Post from "../classes/Post";
import PostSection from "../classes/PostSection";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const [auth, setAuth] =  useState({});

    useEffect(() => {
        Parse.Object.registerSubclass('DictionaryEntry', DictionaryEntry);
        Parse.Object.registerSubclass('ShopCategory', ShopCategory);
        Parse.Object.registerSubclass('SaleEvent', SaleEvent);
        Parse.Object.registerSubclass('Product', Product);
        Parse.Object.registerSubclass('ProductImage', ProductImage);
        Parse.Object.registerSubclass('DocumentariesCategory', DocumentariesCategory);
        Parse.Object.registerSubclass('Documentary', Documentary);
        Parse.Object.registerSubclass('BooksCategory', BooksCategory);
        Parse.Object.registerSubclass('Book', Book);
        Parse.Object.registerSubclass('MusicPlaylist', MusicPlaylist);
        Parse.Object.registerSubclass('Video', Video);
        Parse.Object.registerSubclass('VideoCategory', VideoCategory);
        Parse.Object.registerSubclass('Category', Category);
        Parse.Object.registerSubclass('Podcast', Podcast);
        Parse.Object.registerSubclass('Post', Post);
        Parse.Object.registerSubclass('PostSection', PostSection);
        Parse.Object.registerSubclass('_User', CustomUser);
    }, []);

    return (
        <AuthContext.Provider value={ {auth, setAuth} }>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;