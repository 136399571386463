import React from "react";

import { FiCalendar } from "react-icons/fi";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./date-input.scss"
import {addDays} from "date-fns";

const DateInput = ({id, value, required, minDate, maxDate, onChange}) => {

    return (
        <div className={"Date__input-container"}>
            <DatePicker className="Date__input"
                        dateFormat="dd/MM/yyyy HH:mm"
                        popperClassName="Date__popper"
                        showTimeSelect
                        required={required}
                        minDate={minDate}
                        maxDate={maxDate}
                        timeFormat="HH:mm"
                        selected={value}
                        onChange={onChange}/>
            <FiCalendar className="Date__icon" />
        </div>
    )
}

export default DateInput