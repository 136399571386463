import Parse from "parse";

class BooksCategory extends Parse.Object {

    constructor() {
        super('BooksCategory');
    }

    getTitle() {
        return this.get('title');
    }

    setTitle(title) {
        this.set('title', title)
    }

    getSpanishTitle() {
        return this.get('spanishTitle');
    }

    setSpanishTitle(spanishTitle) {
        this.set('spanishTitle', spanishTitle);
    }

    setPickable(pickable) {
        this.set('pickable', pickable);
    }

    isPickable() {
        return this.get('pickable');
    }

    setEditable(editable) {
        this.set('editable', editable);
    }

    isEditable(editable) {
        return this.get('editable');
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    getCoverPhotoUrl() {
        return this.get("coverPhoto").url();
    }

    setCoverPhotoFile(file) {
        this.set("coverPhoto", file);
    }

    static getPickableQuery() {
        const parseQuery = new Parse.Query(BooksCategory);
        parseQuery.equalTo("enabled", true);
        parseQuery.equalTo("pickable", true);
        parseQuery.ascending("title");
        return parseQuery;
    }

    static getAllQuery() {
        const parseQuery = new Parse.Query(BooksCategory);
        parseQuery.equalTo("enabled", true);
        parseQuery.ascending("title");
        return parseQuery;
    }
}

export default BooksCategory;