import Parse from "parse";
import {Languages} from "../utilities/Constants";
import {transformSaleEventDate} from "../utilities/Transformation";

class SaleEvent extends Parse.Object {
    constructor() {
        super('SaleEvent');
    }

    getTitle() {
        return this.get('title');
    }

    setTitle(title) {
        this.set('title', title)
    }

    getBody() {
        return this.get('body');
    }

    setBody(body) {
        this.set('body', body)
    }

    getImageUrl() {
        return this.get("cover").url()
    }

    setImageFile(file) {
        this.set("cover", file);
    }

    getStartDate() {
        return this.get('startDate');
    }

    getStartDateAsString() {
        return transformSaleEventDate(this.getStartDate());
    }

    setStartDate(startDate) {
        this.set('startDate', startDate)
    }

    getEndDate() {
        return this.get('endDate');
    }

    getEndDateAsString() {
        return transformSaleEventDate(this.getEndDate());
    }

    setEndDate(endDate) {
        this.set('endDate', endDate)
    }

    getLanguage() {
        const languageCode = this.get('languageCodeString');
        return Languages.filter(option => option.key === languageCode)[0]
    }

    setLanguageCode(code) {
        this.set('languageCodeString', code);
    }

    setEnabled(enabled) {
        this.set('enabled', enabled);
    }

    static getQuery() {
        const parseQuery = new Parse.Query(SaleEvent);
        parseQuery.equalTo("enabled", true);
        return parseQuery;
    }

    static getActiveQuery() {
        const parseQuery = new Parse.Query(SaleEvent);
        parseQuery.equalTo("enabled", true);
        parseQuery.lessThanOrEqualTo("startDate", new Date());
        parseQuery.greaterThanOrEqualTo("endDate", new Date());
        parseQuery.ascending("startDate");
        return parseQuery;
    }

    static getPastQuery() {
        const parseQuery = new Parse.Query(SaleEvent);
        parseQuery.equalTo("enabled", true);
        parseQuery.lessThanOrEqualTo("endDate", new Date());
        parseQuery.descending("endDate");
        return parseQuery;
    }

    static getUpcomingQuery() {
        const parseQuery = new Parse.Query(SaleEvent);
        parseQuery.equalTo("enabled", true);
        parseQuery.greaterThanOrEqualTo("startDate", new Date());
        parseQuery.ascending("startDate");
        return parseQuery;
    }
}

export default SaleEvent;