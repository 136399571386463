import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import PageTitle from "../../../components/common/page-title/PageTitle";
import RedButton from "../../../components/common/buttons/RedButton";
import {FiCheckCircle, FiTrash, FiXCircle} from "react-icons/fi";
import LoadingOval from "../../../components/common/loading/LoadingOval";
import InsideContentForm from "../../../components/common/form/InsideContentForm";
import {Col, Container, Row} from "react-grid-system";
import LeftAlignLabelField from "../../../components/common/inputs/right-align-label-field/LeftAlignLabelField";
import TextInput from "../../../components/common/inputs/text-input/TextInput";
import Notice from "../../../components/common/notice/Notice";
import DividerFooter from "../../../components/common/footer/DividerFooter";
import InsideContentFormFooter from "../../../components/common/footer/InsideContentFormFooter";
import Button from "../../../components/common/buttons/Button";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import FileInput, {
    getBase64,
    imageTypes,
    categoryCoverType
} from "../../../components/common/inputs/file-input/FileInput";
import FileUploader from "../../../helpers/FileUploader";
import VideoCategory from "../../../classes/VideoCategory";

const EditVideoCategory = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [videosCategory, setVideosCategory] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const [coverImageLocalFile, setCoverImageLocalFile] = useState(null);
    const [title, setTitle] = useState('');
    const [spanishTitle, setSpanishTitle] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        saveVideosCategory(videosCategory, title, spanishTitle, coverImageLocalFile, (coverImageLocalFile != null));
    };

    useEffect(() => {

        if (params.id === undefined) {
            setLoading(false);
            return;
        }

        let isMounted = true;
        const controller = new AbortController();

        const fetchVideosCategory = async () => {
            try {
                setLoading(true);
                const videosCategory = await VideoCategory.getQuery().get(params.id)

                if (isMounted) {
                    setVideosCategory(videosCategory);
                    setTitle(videosCategory.getTitle());
                    setSpanishTitle(videosCategory.getSpanishTitle());
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchVideosCategory();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const confirmDelete = (videosCategory) => {
        if (videosCategory != null) {
            confirmAlert({
                title: 'Delete videos category',
                message: 'This cannot be undone. Are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteVideosCategory(videosCategory)
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    }

    const deleteVideosCategory = async (videosCategory) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            videosCategory.setEnabled(false);

            await videosCategory.save();

            navigate("/videos-categories");
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (videosCategory === null) {
                cleanValues();
            }
        }
    }

    function cleanValues() {
        setVideosCategory(null);
        setTitle('');
        setSpanishTitle('');
        setCoverImageLocalFile(null);
    }


    const saveVideosCategory = async (videosCategory, title, spanishTitle, file, fileWasChanged) => {
        try {
            setSaving(true);
            setError(null);
            setSuccess(false);

            let pVideosCategory = videosCategory

            if (videosCategory == null) {
                pVideosCategory = new VideoCategory();
            }

            pVideosCategory.setTitle(title);
            pVideosCategory.setSpanishTitle(spanishTitle);

            if (fileWasChanged) {
                const coverImageFile = await FileUploader.uploadFile(file, "videos-category-image-cover", file["type"]);
                pVideosCategory.setImageFile(coverImageFile);
            }

            pVideosCategory.setEnabled(true);
            await pVideosCategory.save();

            setSuccess(true);
        }
        catch (error) {
            console.error(error);
            setSuccess(false);
            setError(error);
        }
        finally {
            setSaving(false);
            if (videosCategory === null) {
                cleanValues();
            }
        }
    }

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];

        getBase64(file)
            .then(result => {
                file["base64"] = result;
                setCoverImageLocalFile(file);
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <div className="VideoCategory">
            <PageTitle title="Videos category"
                       brief="Add a videos category" >
                { videosCategory != null && <RedButton title="Delete" icon={<FiTrash/>} disabled={loading || saving} onClick={() => confirmDelete(videosCategory)} /> }
                <LoadingOval loading={loading} />
            </PageTitle>
            <form onSubmit={handleSubmit}>
                { loading ? <></> :
                    <InsideContentForm>
                        <div className="Form__section-fields">
                            <Container fluid>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="videos-category-cover-image"
                                                             label="Cover image">
                                            <FileInput name="videos-category-cover-image"
                                                       title="Select a cover image"
                                                       accept={imageTypes}
                                                       previewImage={
                                                           coverImageLocalFile !== null ?
                                                               coverImageLocalFile["base64"] :
                                                               videosCategory !== null ?
                                                                   videosCategory.getImageUrl() :
                                                                   null }
                                                       type={categoryCoverType}
                                                       onChange={handleFileInputChange}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="videos-category-title"
                                                             label="Title">
                                            <TextInput id="videos-category-title"
                                                       value={title}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                                <Row gutterWidth={20}>
                                    <Col>
                                        <LeftAlignLabelField id="videos-category-spanish-title"
                                                             label="Spanish title">
                                            <TextInput id="videos-category-spanish-title"
                                                       value={spanishTitle}
                                                       required={true}
                                                       autoComplete={false}
                                                       onChange={(e) => setSpanishTitle(e.target.value)}/>
                                        </LeftAlignLabelField>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        { success &&
                            <Notice icon={<FiCheckCircle/>} type="success" fullWidth={true}>
                                <span>The videos category was saved successfully.</span>
                            </Notice>
                        }
                        { error !== null &&
                            <Notice icon={<FiXCircle />} type="error" fullWidth={true}>
                                { error.message }
                            </Notice>
                        }
                    </InsideContentForm>
                }
                <DividerFooter/>
                <InsideContentFormFooter>
                    <Button title="Cancel" onClick={() => navigate(-1)}/>
                    <PrimaryButton title="Save" type="submit" disabled={saving || loading }/>
                    {saving && <LoadingOval loading={saving}/>}
                </InsideContentFormFooter>
            </form>
        </div>
    );
};

export default EditVideoCategory;