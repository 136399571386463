import React, {useEffect, useState} from 'react';
import PageTitle from "../../../components/common/page-title/PageTitle";
import {Link} from "react-router-dom";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import {FiPlus} from "react-icons/fi";
import FilterHeader from "../../../components/common/header/FilterHeader";
import {Col, Container, Row} from "react-grid-system";
import SelectInput from "../../../components/common/inputs/select-input/SelectInput";
import PaginationTableFooter from "../../../components/common/footer/PaginationTableFooter";
import EcoItemsTable from "../../../components/tables/EcoItemsTable";
import BooksCategory from "../../../classes/BooksCategory";
import Book from "../../../classes/Book";

const Books = () => {

    let allCategoryId = "eiySacg7w2";
    let allCategory = {key: allCategoryId, value: "All"};

    let limit = 15;

    const [books, setBooks] = useState([]);
    const [booksCategories, setBooksCategories] = useState([allCategory]);
    const [loading, setLoading] = useState(true);

    const [selectedCategory, setSelectedCategory] = useState(booksCategories[0]);

    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [count, setCount] = useState(0);
    const [skip, setSkip] = useState(0);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchBooksCategories = async () => {
            try {
                const results = await BooksCategory.getAllQuery().find();
                if (isMounted) {
                    const mappedResults = [];
                    mappedResults.push(...results.map(function(item) { return {
                        key: item.id,
                        value: item.getTitle()
                    }}));
                    setBooksCategories(mappedResults);
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchBooksCategories();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchBooks = async () => {
            try {
                setLoading(true);
                const response = await Book.getFilteredQuery(
                    selectedCategory.key === allCategoryId ? null : selectedCategory.key,
                    skip,
                    limit
                ).find();
                if (isMounted) {
                    setCount(response.count)
                    setNumberOfPages(Math.ceil(response.count / limit))
                    if (response.count === 0) {
                        setCurrentPage(0)
                    }
                    else {
                        setCurrentPage((skip + limit) / limit)
                    }
                    setBooks(response.results);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchBooks();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, [selectedCategory, skip]);

    const booksCategorySelectChangeHandler = (e) => {
        setSelectedCategory(e);
        setSkip(0);
    }

    return (
        <div className="Content-bottom-margin">
            <PageTitle title="Books" brief={
                (selectedCategory ? selectedCategory.value : "All")
            }>
                <Link to="./new">
                    <PrimaryButton title="New book" icon={<FiPlus/>}/>
                </Link>
            </PageTitle>
            <FilterHeader>
                <Container fluid>
                    <Row gutterWidth={20} >
                        <Col sm={12} md={7}>
                            { <SelectInput name="books-categories"
                                           options={booksCategories}
                                           preSelectedOption={selectedCategory}
                                           onChange={booksCategorySelectChangeHandler}/>
                            }
                        </Col>
                    </Row>
                </Container>
            </FilterHeader>
            <EcoItemsTable items={books} loading={loading} footer={
                <PaginationTableFooter loading={loading}
                                       currentPage={currentPage}
                                       numberOfPages={ numberOfPages }
                                       onPrevious={ () => { setSkip(skip - limit) } }
                                       onNext={ () => { setSkip(skip + limit) } } >
                </PaginationTableFooter>
            } />
        </div>
    );
};

export default Books;