import React from 'react';

import "./file-input.scss"
import {FiXCircle} from "react-icons/fi";

const FileInput = ({title, name, accept, previewImage, type, multiple, onChange, onDelete}) => {

    return (
        <div className={"FileInput "
            +  ( type && "FileInput-" + type + " ")
            + (previewImage && "FileInput-preview-border") }>

            { previewImage && <img className={"FileInput__preview-image "
                + (previewImage && type && "FileInput__preview-image-" + type + " ")
                + ( type && "FileInput-" + type + " ") }
                                   src={previewImage}
                                   alt={title} /> }

            <input className="FileInput__input" title={title} type="file" accept={accept} name={name} onChange={onChange} multiple={multiple} />
            { !previewImage && <span className="FileInput__button-span">Upload</span> }
            {onDelete && <span className="FileInput__delete_button-span" onClick={onDelete} ><FiXCircle/></span> }
        </div>
    );
};

export const ecoItemCoverType = "documentary-cover";
export const ecoCategoryCoverType = "documentaries-category-cover";
export const categoryCoverType = "category-cover";
export const saleEventCoverType = "sale-event-cover";
export const videoCoverType = "video-cover";
export const postCoverType = "post-cover";
export const podcastCoverType = "podcast-cover";
export const productImageType = "product-image-cover";
export const postSectionImageType = "post-section-cover";
export const emptyPostSectionImageType = "empty-post-section-cover";
export const imageTypes = "image/png, image/jpeg, image/jpg";
export const audioTypes = "audio/mpeg, audio/mp3";

export const getBase64 = (file) => {
    return new Promise(resolve => {
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

export const asyncGetBase64 = (file) => {
    return new Promise((resolve, reject) => {
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            baseURL = reader.result;
            resolve(baseURL);
        };
        reader.onerror = function() {
            reject(reader.error);
        };
    });
};


export default FileInput;